import React, { createRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import imageKruno from '../assets/img/kruno.jpg';
import imageStillness from '../assets/img/stillness.jpg';
import imageAfroBend from '../assets/img/afrobend.jpg';
import imageArrowDrawn from '../assets/img/arrow-drawn.png';
import imageThatsMe from '../assets/img/maker-thatsme.jpg';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';

export default class ContentMaker extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = createRef();
    }

    render() {
        return (
            <LocomotiveScrollProvider
                options={
                    {
                        smooth: true,
                    }
                }
                watch={[]}
                containerRef={this.containerRef}
            >
                <Helmet>
                    <title>Brainframe | The maker</title>
                    <meta name="title" content="Brainframe | The maker"></meta>
                    <meta name="description" content="Brainframe Chief maker Krunoslav is a professional musician, educator and frame drum builder, based in Croatia (EU)."></meta>
                </Helmet>
                <div data-scroll-container ref={this.containerRef}>
            <div data-scroll-section>
                <section className="section">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-6 has-text-centered">
                                <p className="is-size-4 is-relative has-text-weight-bold mt-xlarge mb-large" data-scroll data-scroll-speed="0">Hi, my name is Krunoslav.</p>
                                <p className="is-size-4 is-relative has-text-weight-light" data-scroll data-scroll-speed="3">Everything in my life has led me to this moment right here. I’m a professional musician, educator, and drum builder, based in Croatia.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="columns is-multiline">
                            <div className="column is-7"></div>
                            <div className="column is-5">
                                <img src={imageKruno} className="process-image-right rounded-transparent square" alt="Krunoslav Kobeščak" />
                            </div>
                            <div className="column is-2"></div>
                            <div className="column is-7">
                                <div className="mb-xlarge">
                                    <h4 className="title is-size-4" data-scroll data-scroll-speed="1">The musician</h4>
                                    <p className="is-size-6 indent-text" data-scroll data-scroll-speed="2">Music has been an integral part of my life for as long as I can recall. Whether in the studio or on stage, as a professional musician, I endeavor to infuse ancient rhythms into every composition or session.<br /><br />Here are some of my professional and hobby artworks:</p>
                                    <div className="is-flex is-flex-direction-row is-align-items-center mt-large" data-scroll data-scroll-speed="3">
                                        <div className="maker-yt is-relative">
                                            <img src={imageStillness} className="square rounded-opaque is-relative" alt="St!llness - Naiva" />
                                            <a className="video-button" href="https://www.youtube.com/watch?v=Pmxizt6R3j4" target="_blank" rel="noreferrer"> </a>
                                        </div>
                                        <p className="is-size-7 is-grey ml-medium">St!llness - Naiva<br />Music video, 2018.</p>
                                    </div>
                                    <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-end mt-large" data-scroll data-scroll-speed="2">
                                        <p className="is-size-7 is-grey mr-medium has-text-right">Jastuk - Spavaj<br />Music Video, 2018.</p>
                                        <div className="maker-yt is-relative">
                                            <img src={imageAfroBend} className="square rounded-opaque is-relative" alt="Jastuk - Spavaj" />
                                            <a className="video-button" href="https://www.youtube.com/watch?v=7s0s1KQE7PM" target="_blank" rel="noreferrer"> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-3"></div>
                        </div>
                        <div className="columns is-multiline">
                            <div className="column is-3"></div>
                            <div className="column is-7">
                                <div className="has-text-right mb-xlarge">
                                    <h4 className="title is-size-4" data-scroll data-scroll-speed="1">An educator</h4>
                                    <p className="is-size-6 indent-text" data-scroll data-scroll-speed="2">Since 2010, I have embraced the belief that both knowledge and happiness flourish through sharing. This conviction has driven my passion for teaching music and rhythms, aiming to spread the joy of music creation from the start.<br /><br />List of workshops:
                                    </p>
                                    <p className="is-size-7 is-grey indent-text mt-small" data-scroll data-scroll-speed="3">“Drumming on river” series of workshops, 2012–2015.<br />Association Most Slunj, Rastoke</p>
                                    <p className="is-size-7 is-grey indent-text mt-small" data-scroll data-scroll-speed="3">“West african percussion” series of workshops, 2012–present.<br />Center for Culture and Film August Cesarec, Zagreb</p>
                                    <p className="is-size-7 is-grey indent-text mt-small" data-scroll data-scroll-speed="3">“Joyful rhythm” — series of workshops, 2011–2021.<br />Association of people with intellectual disabilities, Zagreb</p>
                                </div>
                            </div>
                            <div className="column is-2"></div>
                        </div>
                        <div className="columns is-multiline">
                            <div className="column is-2"></div>
                            <div className="column is-7">
                                <div className="mb-medium">
                                    <h4 className="title is-size-4" data-scroll data-scroll-speed="1">The drum builder</h4>
                                    <p className="is-size-6 indent-text" data-scroll data-scroll-speed="2">My determination to craft drums surpassed every obstacle encountered along the journey, often requiring sacrifices, but ultimately teaching me the hard truth that beginnings are never easy. Despite the challenges, years of dedicated research and development have culminated in the creation of instruments that both I and numerous recognized professional musicians find satisfaction in. This journey has brought me to this very moment.</p>
                                    <a className="button is-rounded is-primary is-outlined is-size-6 mt-medium" href="/process" data-scroll data-scroll-speed="3">More about the process</a>
                                </div>
                            </div>
                            <div className="column is-3"></div>
                            <div className="column is-10 is-offset-2">
                                <div className="is-relative maker-thatsme">
                                    <img src={imageArrowDrawn} width="90" height="90" className="is-pulled-left" alt="" />
                                    <p className="is-size-7 is-grey">That’s me right there in 2011 :)</p>
                                </div>
                            </div>
                            <div className="column is-5">
                                <div className="is-relative">
                                    <img src={imageThatsMe} className="process-image-left rounded-opaque square" alt="The beginnings..." />
                                </div>
                            </div>
                            <div className="column is-7"></div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-7 has-text-centered">
                                <h3 className="title is-size-4 mt-large mb-large" data-scroll data-scroll-speed="1">Choose your drum</h3>
                                <p className="is-size-5 is-relative mt-large mb-large" data-scroll data-scroll-speed="2">Take a look at available drums on our website and get yourself a perfect instrument for stage, studio, or therapy purposes.</p>
                                <div className="mb-xlarge" data-scroll data-scroll-speed="3">
                                    <a className="button is-multiple is-rounded is-primary has-text-black is-size-6" href="/frame-drums">View frame drums</a>
                                    <a className="button is-multiple is-rounded is-primary has-text-black is-size-6" href="/djembe-drums">View djembe drums</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
                </div>
            </LocomotiveScrollProvider>
        );
    }
}