import React from 'react';
import { NavLink } from 'react-router-dom';
import withRouter from './WithRouter';
import logoImage from '../assets/img/bf-logo.svg';
import closeIcon from '../assets/img/close.svg';
import hamburgerIcon from '../assets/img/hamburger.svg';

const activeClass = 'navbar-item is-active';
const inactiveClass = 'navbar-item';

class Navigation extends React.Component {

  changeLocation(placeToGo){
    const { navigate } = this.props;
    navigate(placeToGo, { replace: true });
      window.location.reload();
  }

  toggleBurger(){
      const navbarBasic = document.getElementById('navbarBasic');
      navbarBasic.classList.toggle('is-active');

      const navbarBurger = document.getElementById('navbarBurger');
      navbarBurger.classList.toggle('is-active');

      const navigation = document.getElementById('navigation');
      navigation.classList.toggle('is-active');
  }

  render() {
    return  (
        <nav className="navbar is-transparent is-spaced is-fixed-top" role="navigation" aria-label="main navigation"
             id="navigation">
            <div className="navbar-brand">
                <a className="navbar-item" href="/">
                    <img src={logoImage} width="80" height="80" alt="Brainframe" />
                </a>
                <p className="is-size-5">Brain<b>frame</b></p>

                <a id="navbarBurger" role="button" className="navbar-burger" aria-label="menu" aria-expanded="false"
                   data-target="navbarBasic"  onClick={() => this.toggleBurger('navbarBasic')}>
                    <img aria-hidden="true" src={hamburgerIcon} width="80" height="80" id="nav-hamburger" alt="" />
                    <img aria-hidden="true" src={closeIcon} width="80" height="80" id="nav-close" alt="" />
                </a>
            </div>

            <div id="navbarBasic" className="navbar-menu">
                <div className="navbar-end">
                    <NavLink to="" onClick={() => this.changeLocation('/')} className={({isActive}) => isActive ? activeClass : inactiveClass}>Homepage</NavLink>
                    <NavLink to="frame-drums" onClick={() => this.changeLocation('/frame-drums')} className={({isActive}) => isActive ? activeClass : inactiveClass}>Frame drums</NavLink>
                    <NavLink to="djembe-drums" onClick={() => this.changeLocation('/djembe-drums')} className={({isActive}) => isActive ? activeClass : inactiveClass}>Djembe drums</NavLink>
                    <NavLink to="process" onClick={() => this.changeLocation('/process')} className={({isActive}) => isActive ? activeClass : inactiveClass}>The process</NavLink>
                    <NavLink to="maker" onClick={() => this.changeLocation('/maker')} className={({isActive}) => isActive ? activeClass : inactiveClass}>The maker</NavLink>
                    <NavLink to="shipping" onClick={() => this.changeLocation('/shipping')} className={({isActive}) => isActive ? activeClass : inactiveClass}>Shipping</NavLink>
                </div>
            </div>
        </nav>
    );
  }
}

export default withRouter(Navigation);