import React from 'react';
import {productStatus} from '../const'

export default class ProductListItem extends React.Component {
    render() {
      if (this.props.product.status === productStatus.sold) {
        return (
          <div className="column is-4 has-text-centered sold">
              <div className="product-card">
                <a href={'/frame-drums/' + this.props.product.sn}>
                  <div className="is-relative">
                      <img src={this.props.product.smallImageUrl} className="is-relative" alt={this.props.product.sn} />
                  </div>
                  <h4 className="title is-size-5 has-text-weight-bold mt-medium">
                    {this.props.product.name}
                  </h4>
                  <p className="is-size-5 is-white">{this.props.product.descriptionShort}</p>
                </a>
                <a className="button is-rounded is-primary is-outlined is-size-6 mt-medium" data-scroll data-scroll-speed="1" href={'/frame-drums/' + this.props.product.sn}>More info</a>
              </div>
            </div>
        )
      } else {
        return (
          <div className="column is-4 has-text-centered">
              <div className="product-card">
                <a href={'/frame-drums/' + this.props.product.sn}>
                  <div className="is-relative">
                      <img src={this.props.product.smallImageUrl} className="is-relative" alt={this.props.product.sn} />
                  </div>
                  <h4 className="title is-size-5 has-text-weight-bold mt-medium">
                    {this.props.product.name}
                  </h4>
                  <p className="is-size-5 is-white">{this.props.product.descriptionShort}</p>
                </a>
                <a className="button is-rounded is-primary is-outlined is-size-6 mt-medium" data-scroll data-scroll-speed="1" href={'/frame-drums/' + this.props.product.sn}>More info</a>
              </div>
            </div>
        );
      }
    }
}