import React, { createRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import Footer from './Footer';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';


const FORM_HANDLER_API_URL = 'https://tv6qudu245tzvlpcmwjrwghory0nbxvv.lambda-url.eu-central-1.on.aws/';

export default class ContentContact extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();

    let formFields = {
      firstName: '',
      lastName: '',
      companyName: '',
      phoneNumber: '',
      email: '',
      subject: '',
      message: '',
    };

    let validationStates = {
      invalidFirstName: false,
      invalidLastName: false,
    };

    this.state = {
      ...formFields,
      ...validationStates
    };

    this.state['formIsBeingProcessedOnBackend'] = false;
    this.state['formJustFinishedProcessingOnBackend'] = false;
    this.state['formWasSuccessfullySubmitted'] = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let newState = {};
    newState[event.target.name] = event.target.value;

    //console.log(event.target.name);
    //console.log(newState);

    this.setState(newState);
  }

  handleSubmit(event) {
    // prevent standard form submit
    event.preventDefault();

    // validate form data
    let formIsValid = this.validateFormData();

    // if form is valid, show the spinner and send the form data to the API endpoint
    if (formIsValid) {
      // make a POST call to the handleFormSubmit lambda function URL
      this.postFormDataToAPI();
    }
  }

  validateFormData() {
    this.setState({ invalidFirstName: this.state.firstName === '' });

    return !this.state.invalidFirstName;
  }

  postFormDataToAPI() {
    console.log('Call to API');

    // show "spinner" and hide status messages (if not already hidden)
    this.setState({
      'formIsBeingProcessedOnBackend': true,
      'formJustFinishedProcessingOnBackend': false,
      'formWasSuccessfullySubmitted': null
    });

    let formData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      companyName: this.state.companyName,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message
    };

    let postPayload = { 'formData': JSON.stringify(formData), 'formType': 'contact' };
    console.log(postPayload);

    axios.post(FORM_HANDLER_API_URL, postPayload).then((response) => {
      //console.log('RESPONSE: ');
      //console.log(response.data);
      this.clearFormData();

      // hide spinner
      // and show status message
      if (response.status === 200) {
        this.setState({
          'formIsBeingProcessedOnBackend': false,
          'formJustFinishedProcessingOnBackend': true,
          'formWasSuccessfullySubmitted': true
        });
      } else {
        this.setState({
          'formIsBeingProcessedOnBackend': false,
          'formJustFinishedProcessingOnBackend': true,
          'formWasSuccessfullySubmitted': false
        });
      }

      setTimeout(() => {
        this.setState({
          'formIsBeingProcessedOnBackend': false,
          'formJustFinishedProcessingOnBackend': false,
          'formWasSuccessfullySubmitted': null
        });

      }, 3000);
    });
  }

  clearFormData() {
    this.setState({
      firstName: '',
      lastName: '',
      companyName: '',
      phoneNumber: '',
      email: '',
      subject: '',
      message: '',
    });
  }

  render() {
    return (
        <LocomotiveScrollProvider
            options={
              {
                smooth: true,
              }
            }
            watch={[]}
            containerRef={this.containerRef}
        >
          <Helmet>
            <title>Brainframe | Contact</title>
            <meta name="title" content="Brainframe | Contact"></meta>
            <meta name="description" content="Get in touch with Brainframe."></meta>
          </Helmet>
          <div data-scroll-container ref={this.containerRef}>
      <div data-scroll-section>
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-6 has-text-centered">
                <h3 className="title is-size-4 mt-xlarge mb-large" data-scroll data-scroll-speed="0">Get in touch with Brainframe</h3>
                <p className="is-size-4 is-relative has-text-weight-light" data-scroll data-scroll-speed="2">Contact us by sending an email to <a href="mailto:hello@brainframedrums.com">hello@brainframedrums.com</a> <br />or call <a href="tel:+385919749608">+385&nbsp;91&nbsp;9749&nbsp;608</a>.</p>
                <p className="is-size-4 is-relative has-text-weight-light mt-medium mb-large" data-scroll data-scroll-speed="1">We’ll get back to you as soon as possible.</p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
          </div>
        </LocomotiveScrollProvider>
    );
  }
}