import React, { createRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import withRouter from './WithRouter';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
import imageWoodWalnut from '../assets/img/wood-walnut.png';
import imageWoodOak from '../assets/img/wood-oak.png';
import imageWoodAsh from '../assets/img/wood-ash.png';
import imageWoodCherry from '../assets/img/wood-cherry.png';
import imageWoodMaple from '../assets/img/wood-maple.png';
import imageSkinGoat from '../assets/img/skin-goat.png';
import imageSkinCalf from '../assets/img/skin-calf.png';
import imageSkinDeer from '../assets/img/skin-deer.png';
import imageSkinHorse from '../assets/img/skin-horse.png';
import imageSkinSheep from '../assets/img/skin-sheep.png';

class ContentCustom extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();

    this.state = {
        diameter: '12',
        wood: 'walnut',
        skin: 'calf',
        customText: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.placeOrder = this.placeOrder.bind(this);
  }

  handleChange(event) {
    let newState = {};
    newState[event.target.getAttribute('data-name')] = event.target.getAttribute('data-value');
    this.setState(newState);
  }

  placeOrder()
  {
    // build a product entity

    let product = {
        'sn': 'CUSTOM BUILT FRAME DRUM',
        'name': 'CUSTOM BUILT FRAME DRUM',
        'description': 'N/A',
        'diameterInch': this.state.diameter,
        'diameterCm': 'N/A',
        'skin': this.state.skin,
        'wood': this.state.wood,
        'price': 'N/A'
    }

    // navigate to the order form
    this.props.navigate('/order', {'state': {'product': product}});    
  }

  render() {
    return (
        <LocomotiveScrollProvider
            options={
                {
                    smooth: true,
                }
            }
            watch={[]}
            containerRef={this.containerRef}
        >
            <div data-scroll-container ref={this.containerRef}>
            <Helmet>
            <title>Brainframe | Make your own frame drum</title>
            <meta name="title" content="Brainframe | Make your own frame drum"></meta>
            <meta name="description" content="If you already have a favorite wood for its aesthetics or sound characteristics and know how your drum should sound like, make your own combination."></meta>
          </Helmet>
<div data-scroll-section>
  <section className="section">
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-7 has-text-centered">
          <h3 className="title is-size-4 mt-xlarge mb-large" data-scroll data-scroll-speed="0">Make your own frame drum</h3>
          <p className="is-size-4 is-relative has-text-weight-light" data-scroll data-scroll-speed="1">If you already have a favorite wood for its aesthetics or sound characteristics and know how your drum should sound like, make your own combination with available items below.</p>
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
        <form>
        <div className="columns is-centered is-multiline">
            <div className="column is-12 has-text-centered">
                <h4 className="title is-size-6 is-uppercase">Choose drum diameter</h4>
                <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
                    <div className={this.state.diameter === '12' ? "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small is-selected" : "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small"} onClick={this.handleChange} data-name="diameter" data-value="12">12”</div>
                    <div className={this.state.diameter === '14' ? "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small is-selected" : "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small"} onClick={this.handleChange} data-name="diameter" data-value="14">14”</div>
                    <div className={this.state.diameter === '16' ? "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small is-selected" : "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small"} onClick={this.handleChange} data-name="diameter" data-value="16">16”</div>
                    <div className={this.state.diameter === '18' ? "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small is-selected" : "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small"} onClick={this.handleChange} data-name="diameter" data-value="18">18”</div>
                    <div className={this.state.diameter === '20' ? "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small is-selected" : "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small"} onClick={this.handleChange} data-name="diameter" data-value="20">20”</div>
                    <div className={this.state.diameter === '22' ? "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small is-selected" : "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small"} onClick={this.handleChange} data-name="diameter" data-value="22">22”</div>
                    <div className={this.state.diameter === '24' ? "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small is-selected" : "customize-form-item is-size-4 is-flex is-align-items-center is-justify-content-center mb-small"} onClick={this.handleChange} data-name="diameter" data-value="24">24”</div>
                </div>
            </div>
            <div className="column is-12 has-text-centered">
                <h4 className="title is-size-6 is-uppercase mt-large">Choose wood frame</h4>
                <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
                    <div className="mb-small">
                        <img src={imageWoodWalnut} className={this.state.wood === 'walnut' ? "customize-form-item square is-selected" : "customize-form-item square"} data-name="wood" data-value="walnut" alt="Walnut" onClick={this.handleChange}/>
                        <p className="is-size-7 is-uppercase is-grey">Walnut</p>
                    </div>
                    <div className="mb-small">
                        <img src={imageWoodOak} className={this.state.wood === 'oak' ? "customize-form-item square is-selected" : "customize-form-item square"} data-name="wood" data-value="oak" alt ="Oak" onClick={this.handleChange} />
                        <p className="is-size-7 is-uppercase is-grey">Oak</p>
                    </div>
                    <div className="mb-small">
                        <img src={imageWoodCherry} className={this.state.wood === 'cherry' ? "customize-form-item square is-selected" : "customize-form-item square"} data-name="wood" data-value="cherry" alt="Cherry" onClick={this.handleChange} />
                        <p className="is-size-7 is-uppercase is-grey">Cherry</p>
                    </div>
                    <div className="mb-small">
                        <img src={imageWoodAsh} className={this.state.wood === 'ash' ? "customize-form-item square is-selected" : "customize-form-item square"} data-name="wood" data-value="ash" alt="Ash" onClick={this.handleChange} />
                        <p className="is-size-7 is-uppercase is-grey">Ash</p>
                    </div>
                    <div className="mb-small">
                        <img src={imageWoodMaple} className={this.state.wood === 'maple' ? "customize-form-item square is-selected" : "customize-form-item square"} data-name="wood" data-value="maple" alt="Maple" onClick={this.handleChange} />
                        <p className="is-size-7 is-uppercase is-grey">Maple</p>
                    </div>
                </div>
            </div>
            <div className="column is-12 has-text-centered">
                <h4 className="title is-size-6 is-uppercase mt-large">Choose drum skin</h4>
                <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
                    <div className="mb-small">
                        <img src={imageSkinCalf} className={this.state.skin === 'calf' ? "customize-form-item square is-selected" : "customize-form-item square"} data-name="skin" data-value="calf" alt="Calf" onClick={this.handleChange} />
                        <p className="is-size-7 is-uppercase is-grey">Calf</p>
                    </div>
                    <div className="mb-small">
                        <img src={imageSkinDeer} className={this.state.skin === 'deer' ? "customize-form-item square is-selected" : "customize-form-item square"} data-name="skin" data-value="deer" alt="Deer" onClick={this.handleChange}  />
                        <p className="is-size-7 is-uppercase is-grey">Deer</p>
                    </div>
                    <div className="mb-small">
                        <img src={imageSkinGoat} className={this.state.skin === 'goat' ? "customize-form-item square is-selected" : "customize-form-item square"} data-name="skin" data-value="goat" alt="Goat" onClick={this.handleChange}  />
                        <p className="is-size-7 is-uppercase is-grey">Goat</p>
                    </div>
                    <div className="mb-small">
                        <img src={imageSkinHorse} className={this.state.skin === 'horse' ? "customize-form-item square is-selected" : "customize-form-item square"} data-name="skin" data-value="horse" alt="Horse" onClick={this.handleChange}  />
                        <p className="is-size-7 is-uppercase is-grey">Horse</p>
                    </div>
                    <div className="mb-small">
                        <img src={imageSkinSheep} className={this.state.skin === 'sheep' ? "customize-form-item square is-selected" : "customize-form-item square"} data-name="skin" data-value="sheep" alt="Sheep" onClick={this.handleChange}  />
                        <p className="is-size-7 is-uppercase is-grey">Sheep</p>
                    </div>
                </div>
            </div>
            <div className="column is-5 has-text-centered">
                <h4 className="title is-size-6 is-uppercase mt-large">Engrave custom text</h4>
                <p className="is-size-7 my-medium">We're able to engrave max. 30 characters on the inside of the frame.</p>
                <div className="field has-text-left">
                    <label className="label">
                      <input className="input is-rounded is-medium" type="text" placeholder=" " />
                      <div className="label-text">Enter custom text</div>
                    </label>                       
                </div>
            </div>
            <div className="column is-12 has-text-centered">
                <div className="field mb-xlarge" data-scroll data-scroll-speed="1">
                    <div className="control">
                      <button className="button is-rounded is-primary has-text-black is-size-6" onClick={this.placeOrder}>Next step</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    </div>
  </section>
<Footer />
</div>
            </div>
        </LocomotiveScrollProvider>
    );
  }
}

export default withRouter(ContentCustom);
