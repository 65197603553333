import React from 'react';
import withRouter from './WithRouter';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import {woodImages, skinImages} from '../const';
import ScrollToTop from './ScrollToTop';

const FORM_HANDLER_API_URL = 'https://2cbexl72psuopkagqzdjnrk3640vrywx.lambda-url.eu-west-3.on.aws/';

class ContentOrder extends React.Component {
  constructor(props) {
    super(props);
    this.product = this.props.location.state.product;

    let formFields = {
      firstName: '',
      lastName: '',
      companyName: '',
      phoneNumber: '',
      email: '',
      vatNumber: '',
      shippingAddress1: '',
      shippingAddress2: '',
      city: '',
      zipCode: '',
      country: ''
    };

    this.state = {
      ...formFields,
    };

    this.state['formIsBeingProcessedOnBackend'] = false;
    this.state['formJustFinishedProcessingOnBackend'] = false;
    this.state['formWasSuccessfullySubmitted'] = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);    
  }

  handleChange(event) {
    let newState = {};
    newState[event.target.name] = event.target.value;

    //console.log(event.target.name);
    //console.log(newState);

    this.setState(newState);
  }

  handleSubmit(event) {
    // prevent standard form submit
    event.preventDefault();

    // make a POST call to the handleFormSubmit lambda function URL
    this.postFormDataToAPI();
  }

  postFormDataToAPI() {
    console.log('Call to API');

    // show "spinner" and hide status messages (if not already hidden)
    this.setState({
      'formIsBeingProcessedOnBackend': true,
      'formJustFinishedProcessingOnBackend': false,
      'formWasSuccessfullySubmitted': null
    });

    let formData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      companyName: this.state.companyName,
      phoneNumber: this.state.phoneNumber,
      vatNumber: this.state.vatNumber,
      email: this.state.email,
      shippingAddress1: this.state.shippingAddress1,
      shippingAddress2: this.state.shippingAddress2,
      city: this.state.city,
      zipCode: this.state.zipCode,
      country: this.state.country
    };

    let postPayload = { formData: formData, productData: this.product};
    console.log(postPayload);

    axios.post( FORM_HANDLER_API_URL, postPayload)
        .then((response) => {
            console.log('RESPONSE: ');
            console.log(`Status: ${response.status}`);
            console.log(`Body: ${response.data}`);

            if (response.status === 200) {
                this.setState({
                  'formIsBeingProcessedOnBackend': false,
                  'formJustFinishedProcessingOnBackend': true,
                  'formWasSuccessfullySubmitted': true
                });
            } else {
                this.setState({
                  'formIsBeingProcessedOnBackend': false,
                  'formJustFinishedProcessingOnBackend': true,
                  'formWasSuccessfullySubmitted': false
                });
            }
        })
        .catch((err) => {
            console.log('status' + err.response.status);
            console.error('data' + err.response.data);
        }
    );
  }

  render() {
    return (

<div data-scroll-section>
    <ScrollToTop />
    <Helmet>
      <title>Brainframe | Order</title>
      <meta name="title" content="Brainframe | Order"></meta>
      <meta name="description" content="Artisan drums hand-made in Europe. Shop now, we ship worldwide!"></meta>
    </Helmet>
<section className="section">
    <div className="container" style={(this.state.formJustFinishedProcessingOnBackend && this.state.formWasSuccessfullySubmitted) ? { display: 'block' } : { display: 'none' }}>
      <div className="columns is-centered">
        <div className="column is-7 has-text-centered">
          <h3 className="title is-size-4 mt-xlarge" data-scroll data-scroll-speed="0">YOUR ORDER WAS SENT</h3>
          <p className="is-size-4 mt-small" data-scroll data-scroll-speed="0.5">You should receive order confirmation mail shortly. Please check your e-mail.</p>
          <div className="field mb-large mt-xlarge" data-scroll data-scroll-speed="0.4">
            <div className="control">
              <a className="button is-rounded is-primary has-text-black is-size-6" href="/">Back to homepage</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div className="container" style={(this.state.formJustFinishedProcessingOnBackend && !this.state.formWasSuccessfullySubmitted) ? { display: 'block' } : { display: 'none' }}>
      <div className="columns is-centered">
        <div className="column is-7 has-text-centered">
          <h3 className="title is-size-4 mt-xlarge" data-scroll data-scroll-speed="0">OOOOPS, SOMETHING WENT WRONG</h3>
          <p className="is-size-4 mt-small" data-scroll data-scroll-speed="0.5">There was a problem with sending your order. Please try again or contacting us on mail <a href="mailto:hello@brainframedrums.com?Subject: Prolem with order">hello@brainframedrums.com</a>.</p>
          <div className="field mb-large mt-xlarge" data-scroll data-scroll-speed="0.4">
            <div className="control">
              <a className="button is-rounded is-primary has-text-black is-size-6" href="/drums">Back to frame drums</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container" style={(!this.state.formJustFinishedProcessingOnBackend && !this.state.formIsBeingProcessedOnBackend) ? { display: 'block' } : { display: 'none' }}>
      <div className="columns is-centered">
        <div className="column is-7 has-text-centered">
          <h3 className="title is-size-4 mt-xlarge" data-scroll data-scroll-speed="0">ORDER {this.product.name}</h3>
            <div className="product-info-specs is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-space-around is-align-items-flex-end p-medium mt-medium" data-scroll data-scroll-speed="0.75">
                {this.product.diameterInch 
                ? 
                <div className="has-text-centered">
                  <div>
                    <p className="is-size-5 leading-tight pb-small"> {this.product.diameterInch}”<br />{!isNaN(this.product.diameterCm) ? this.product.diameterCm : Math.round(this.product.diameterInch * 2.54 / 5) * 5}cm</p>
                    <p className="is-size-7 is-uppercase is-grey">Diameter</p>
                  </div>
                </div>
                :
                <div className="has-text-centered">
                  <div>
                    <p className="is-size-3 has-text-weight-light leading-tight pb-small"> {this.product.size}</p>
                    <p className="is-size-7 is-uppercase is-grey">Size</p>
                  </div>
                </div>
                }

                <div className="has-text-centered">
                <img src={woodImages[this.product.wood]} className="square rounded-opaque" alt={this.product.wood + ' frame'} />
                <p className="is-size-7 is-uppercase is-grey">{this.product.wood} wood</p>
                </div>
                <div className="has-text-centered">
                <img src={skinImages[this.product.skin]} className="square rounded-opaque" alt={this.product.skin + ' skin'} />
                <p className="is-size-7 is-uppercase is-grey"> {this.product.skin.includes('djembe') ? this.product.skin.slice(6) : this.product.skin} skin</p>
                </div>
            </div>
            <p className="is-size-4 mt-small" style={!isNaN(this.product.price) ? {display: 'block'} : {display: 'none'}} data-scroll data-scroll-speed="0.5">Price without shipping: <b>€{!isNaN(this.product.price) ? this.product.price.toFixed(2) : ' -'}</b></p>
        </div>
      </div>
    </div>

    <div className="container" style={(!this.state.formJustFinishedProcessingOnBackend && !this.state.formIsBeingProcessedOnBackend) ? { display: 'block' } : { display: 'none' }}>
      <div className="columns is-centered">
        <div className="column is-6 has-text-centered">
            <form data-scroll data-scroll-speed="0" onSubmit={this.handleSubmit}>
                <h3 className="title is-size-6 mt-xlarge">Customer details</h3>
                
                <div className="field has-text-left">
                  <div className="control">
                    <label className="label">
                      <input className="input is-rounded is-medium" name="email" value={this.state.email} onChange={this.handleChange} type="email" placeholder=" " required />
                      <div className="label-text">Email address</div>
                    </label>
                  </div>          
                </div>

                <div className="field has-text-left">
                    <label className="label">
                      <input className="input is-rounded is-medium" name="firstName" value={this.state.firstName} onChange={this.handleChange} type="text" placeholder=" " required />
                      <div className="label-text">First name</div>
                    </label>                       
                </div>

                <div className="field has-text-left">
                    <label className="label"></label>
                    <div className="control">
                        <input className="input is-rounded is-medium" name="lastName" value={this.state.lastName} onChange={this.handleChange} type="text" placeholder=" " />
                        <div className="label-text">Last name</div>
                    </div>
                </div>

                <div className="field has-text-left">
                  <label className="label">
                    <input className="input is-rounded is-medium" name="phoneNumber" value={this.state.phoneNumber} onChange={this.handleChange} type="tel" placeholder=" " />
                    <div className="label-text">Phone number (optional)</div>
                  </label>                       
                </div>

                <div className="field has-text-left">
                  <label className="label">
                    <input className="input is-rounded is-medium" name="companyName" value={this.state.companyName} onChange={this.handleChange} type="text" placeholder=" " />
                    <div className="label-text">Company name (optional)</div>
                  </label>                       
                </div>
                  
                <div className="field has-text-left">
                  <label className="label">
                    <input className="input is-rounded is-medium" name="vatNumber" value={this.state.vatNumber} onChange={this.handleChange} type="text" placeholder=" " />
                    <div className="label-text">VAT ID (optional)</div>
                  </label>                       
                </div>

                <h3 className="title is-size-6 mt-large">Shipping & billing address</h3>

                <div className="field has-text-left">
                  <div className="control">
                    <label className="label">
                      <input className="input is-rounded is-medium" name="shippingAddress1" value={this.state.shippingAddress1} onChange={this.handleChange} type="text" placeholder=" " required />
                      <div className="label-text">Address line 1</div>
                    </label>
                  </div>          
                </div>

                <div className="field has-text-left">
                  <div className="control">
                    <label className="label">
                      <input className="input is-rounded is-medium" name="shippingAddress2" value={this.state.shippingAddress2} onChange={this.handleChange} type="text" placeholder=" " />
                      <div className="label-text">Address line 2 (optional)</div>
                    </label>
                  </div>          
                </div>

                <div className="field has-text-left">
                  <div className="control">
                    <label className="label">
                      <input className="input is-rounded is-medium" name="city" value={this.state.city} onChange={this.handleChange} type="text" placeholder=" " required />
                      <div className="label-text">City</div>
                    </label>
                  </div>          
                </div>

                <div className="field has-text-left">
                  <div className="control">
                    <label className="label">
                      <input className="input is-rounded is-medium" name="zipCode" value={this.state.zipCode} onChange={this.handleChange} type="text" placeholder=" " required />
                      <div className="label-text">Post or ZIP code</div>
                    </label>
                  </div>          
                </div>

                <div className="field has-text-left">
                    <div className="control">
                        <div className="select is-rounded is-medium">
                            <select name="country" value={this.state.country} onChange={this.handleChange} required>
                                <option value="" disabled >Country</option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">American Samoa</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                <option value="Argentina">Argentina</option>
                                <option value="France">France</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Ascension">Ascension</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Virgin Islands">British Virgin Islands</option>
                                <option value="Brunei">Brunei</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Cayman Is.">Cayman Is.</option>
                                <option value="Central African Rep.">Central African Rep.</option>
                                <option value="Chad">Chad</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island (Austr.)">Christmas Island (Austr.)</option>
                                <option value="Cocos (Keeling) Is.">Cocos (Keeling) Is.</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Congo">Congo</option>
                                <option value="Cook Islands">Cook Islands</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Cote dIvoire">Côte d'Ivoire</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">Czech Republic</option>
                                <option value="D.P.R. of Korea">D.P.R. of Korea</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">Dominican Republic</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Eswatini">Eswatini</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland Isl. (Malvinas)">Falkland Isl. (Malvinas)</option>
                                <option value="Faroe Islands">Faroe Islands</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French Guiana">French Guiana</option>
                                <option value="French Polynesia">French Polynesia</option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Iran">Iran</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Isle of Man">Isle of Man</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Kosovo">Kosovo</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Laos">Laos</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libya">Libya</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macau">Macau</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Mariana Islands">Mariana Islands</option>
                                <option value="Marshall Islands">Marshall Islands</option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Micronesia">Micronesia</option>
                                <option value="Moldavia">Moldavia</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montenegro">Montenegro</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="Netherlands Antilles">Netherlands Antilles</option>
                                <option value="New Caledonia">New Caledonia</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">Norfolk Island</option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">Papua New Guinea</option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Pitcairn Islands">Pitcairn Islands</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Republic of Korea">Republic of Korea</option>
                                <option value="Republic of North Macedonia">Republic of North Macedonia</option>
                                <option value="Réunion">Réunion</option>
                                <option value="Romania">Romania</option>
                                <option value="Russian Federation">Russian Federation</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Saint Helena">Saint Helena</option>
                                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                <option value="Saint Lucia">Saint Lucia</option>
                                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                <option value="Salvador">Salvador</option>
                                <option value="Samoa">Samoa</option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                <option value="Saudi Arabia">Saudi Arabia</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Serbia">Serbia</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">Sierra Leone</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Syria">Syria</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">Solomon Islands</option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Africa">South Africa</option>
                                <option value="South Georgia">South Georgia</option>
                                <option value="South Sandwich Is.">South Sandwich Is.</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Taiwan">Taiwan</option>
                                <option value="Tanzania">Tanzania</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Timor-Leste">Timor-Leste</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                <option value="Tristan da Cunha">Tristan da Cunha</option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">Turkmenistan</option>
                                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="Union of the Comoros">Union of the Comoros</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="United States of America">United States of America</option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Vatican">Vatican</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Vietnam">Vietnam</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                        </div>
                    </div>
                </div>
                  
                <p className="m-medium" data-scroll data-scroll-speed="0">After placing your order you will receive an e-mail with a proforma invoice with payment and delivery information and instructions.</p>
                  
                <div className="field mb-xlarge" data-scroll data-scroll-speed="0.4">
                    <div className="control">
                      <button className="button is-rounded is-primary has-text-black is-size-6" disabled={this.state.formIsBeingProcessedOnBackend}>Place an order</button>
                    </div>
                </div>
            </form>
        </div>
      </div>
    </div>
  </section>  


<Footer />
</div>
    );
  }
}


export default withRouter(ContentOrder);
