import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import ContentHome from './components/ContentHome';
import ContentMaker from './components/ContentMaker';
import ContentProcess from './components/ContentProcess';
import ContentShipping from './components/ContentShipping';
import ContentDrums from './components/ContentDrums';
import ContentDjembe from './components/ContentDjembe';
import ContentContact from './components/ContentContact';
import ContentProduct from './components/ContentProduct';
import ContentProductDjembe from './components/ContentProductDjembe';
import ContentCustom from './components/ContentCustom';
import ContentOrder from './components/ContentOrder';
import ContentLegal from './components/ContentLegal';
import ContentMusicTherapy from './components/ContentMusicTherapy';
import { HelmetProvider } from 'react-helmet-async';
import React from 'react';
import Navigation from "./components/Navigation";
import Facebook from "./components/FacebookPixel";

export default class App extends React.Component {
  render() {
    return (
      <HelmetProvider>
        <div data-scroll-container ref={this.containerRef}>
          <main>
            <div className="App" >
              <BrowserRouter>
                <Facebook />
                <Routes>
                  <Route
                    path="/"
                    element={
                      <ContentHome />
                    } />
                  <Route
                    path="/maker"
                    element={
                      <ContentMaker />
                    } />
                  <Route
                    path="/process"
                    element={
                      <ContentProcess />
                    } />
                  <Route
                    path="/shipping"
                    element={
                      <ContentShipping />
                    } />
                  <Route
                    path="/frame-drums"
                    element={
                      <ContentDrums products={this.props.products} />
                    } />
                  <Route
                    path="/djembe-drums"
                    element={
                      <ContentDjembe productsDjembe={this.props.productsDjembe} />
                    } />
                  <Route
                    path="/contact"
                    element={
                      <ContentContact />
                    } />
                  <Route
                    path="/frame-drums/:id"
                    element={
                      <ContentProduct products={this.props.products} />
                    } />
                  <Route
                    path="/djembe-drums/:id"
                    element={
                      <ContentProductDjembe productsDjembe={this.props.productsDjembe} />
                    } />
                  <Route
                    path="/custom"
                    element={
                      <ContentCustom />
                    } />
                  <Route
                    path="/order"
                    element={
                      <ContentOrder />
                    } />
                  <Route
                    path="/legal"
                    element={
                      <ContentLegal />
                    } />
                    <Route
                    path="/musictherapy"
                    element={
                      <ContentMusicTherapy />
                    } />
                    <Route
                        path="/drums"
                        element={<Navigate to='/frame-drums' />}
                    />
                   {<Route
                        path="/drums/:id"
                        element={<ContentProduct products={this.props.products} />}
                    />}
                </Routes>
                {window.location.pathname !== '/musictherapy' && <Navigation />}
              </BrowserRouter>
            </div>
          </main>
        </div>
      </HelmetProvider>
    );
  }
}