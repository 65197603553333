import React, { createRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import withRouter from './WithRouter';
import imageProductTuning from '../assets/img/product-tuning.jpg';
import imageProductCraft from '../assets/img/product-craft.jpg';
import imageProductNature from '../assets/img/product-nature.jpg';
import imageProductSound from '../assets/img/product-sound.jpg';
import imageBoxDrum from '../assets/img/product-frame-drum.jpg';
import imageBoxBag from '../assets/img/product-frame-bag.jpg';
import imageBoxPump from '../assets/img/product-frame-pump.jpg';
import imageCustomWood from '../assets/img/custom-wood.svg';
import imageCustomLeather from '../assets/img/custom-leather.svg';
import imageCustomPattern from '../assets/img/custom-pattern.svg';
import imagePlus from '../assets/img/plus.svg';
import Footer from './Footer';
import {woodImages, skinImages, productStatus} from '../const';
import { Helmet } from 'react-helmet-async';
import {Navigate} from "react-router-dom";

class ContentProduct extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
    this.id = props.params.id;
    this.location = props.location.pathname;

    this.productIndex = props.products.findIndex(product => product.sn === this.id);

    this.product = null;

    if (!isNaN(this.productIndex)) {
      this.product = props.products[this.productIndex];
    }

    this.wood = this.product.wood.charAt(0).toUpperCase() + this.product.wood.slice(1);
    this.skin = this.product.skin.charAt(0).toUpperCase() + this.product.skin.slice(1);

    this.imageCount = this.product.images.length;
    this.rotationImages = 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/' + this.product.sn + '/##.png|1..' + this.imageCount;
    this.mainImage = this.product.mainImageUrl;
    this.backImage= this.product.images[0];

    this.audios = {
      'audioLow': new Audio(this.product.audioLowPitchUrl),
      'audioHigh': new Audio(this.product.audioHighPitchUrl),
    };

    this.state = { 'audioLowPlay': false, 'audioHighPlay': false, 'mainImage': true };
    this.currentlyPlaying = null;

    this.placeOrder = this.placeOrder.bind(this);
  }

  placeOrder(event) {
    event.stopPropagation();
    // redirect to the order form  
    this.props.navigate('/order', {'state': {'product': this.product}});
  }

  switchImage(){
    this.setState({'mainImage': !this.state.mainImage});
  }

  togglePlay(audioName) {
    const audioState = `${audioName}Play`;
    this.setState({[audioState]: !this.state[audioState]}, () => {
      this.state[audioState] ? this.play(audioName) : this.pause(audioName);
    });
  }

  play(name){
    if (this.currentlyPlaying) {
      this.setState({[`${this.currentlyPlaying}Play`]: false}, () => {
        this.audios[this.currentlyPlaying].pause();
        this.audios[name].currentTime = 0;
        this.audios[name].play();
        this.currentlyPlaying = name;
      });
    } else {
      this.audios[name].currentTime = 0;
      this.audios[name].play();
      this.currentlyPlaying = name;
    }
  };

  pause(name){
    this.currentlyPlaying = null;
    this.audios[name].pause();
  };

  render()
  {
    return (
        <LocomotiveScrollProvider
            options={
              {
                smooth: true,
              }
            }
            watch={[]}
            containerRef={this.containerRef}
        >
          {this.location.includes('/drums/') && (this.location=`/frame-drums/${this.id}`) && (
              <Navigate to={`/frame-drums/${this.id}`} replace={true}/>
          )}
          <Helmet>
            <title>Brainframe | Frame drum {this.product.name}</title>
            <meta name="title" content={this.product.name}></meta>
            <meta name="description" content={this.product.descriptionLong}></meta>
          </Helmet>
          <div data-scroll-container ref={this.containerRef}>
        <div data-scroll-section>

          <section className="section">
            <div className="container">
              <div className="columns is-centered is-multiline mt-xlarge">
                <div className="column is-6">
                  <div className="product-visual is-relative has-text-centered">
                    <div onClick={() => this.switchImage()} style={{display: 'block'}}>
                      <img src={this.state.mainImage ? this.mainImage : this.backImage}
                           className="is-relative is-inline-block reel" alt={this.product.name}
                      />
                      <button className="button is-rounded">Click to rotate</button>
                    </div>
                  </div>
                  <div className="has-text-centered product-audio mt-small" data-scroll data-scroll-speed="2">
                    <p className="is-grey is-size-7 is-uppercase">Listen to audio samples</p>

                    {this.state.audioLowPlay
                          ? <button className="button is-rounded is-primary is-outlined is-size-7 mr-small my-small is-playing audio-button" onClick={() => this.togglePlay('audioLow')}>Low pitch</button>
                          : <button className="button is-rounded is-primary is-outlined is-size-7 mr-small my-small  audio-button" onClick={() => this.togglePlay('audioLow')}>Low pitch</button>}

                    {this.state.audioHighPlay
                          ? <button className="button is-rounded is-primary is-outlined is-size-7 my-small is-playing audio-button" onClick={() => this.togglePlay('audioHigh')}>High pitch</button>
                          : <button className="button is-rounded is-primary is-outlined is-size-7 my-small audio-button" onClick={() => this.togglePlay('audioHigh')}>High pitch</button>}

                  </div>
                </div>
                <div className="column is-6 product-info has-text-centered">
                  <h3 className="title is-size-4 mb-small" data-scroll data-scroll-speed="1">{this.product.name}</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">{this.product.descriptionLong}</p>

                  <div
                      className="product-info-specs is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-space-around is-align-items-flex-end p-medium mt-medium"
                      data-scroll data-scroll-speed="1">
                    <div className="has-text-centered">
                      <p className="is-size-5 leading-tight pb-small">{this.product.diameterInch}”<br/>{this.product.diameterCm}cm
                      </p>
                      <p className="is-size-7 is-uppercase is-grey">Diameter</p>
                    </div>
                    <div className="has-text-centered">
                      <img src={woodImages[this.product.wood]} className="square rounded-opaque" alt={this.wood} />
                      <p className="is-size-7 is-uppercase is-grey">{this.wood} frame</p>
                    </div>
                    <div className="has-text-centered">
                      <img src={skinImages[this.product.skin]} className="square rounded-opaque" alt={this.skin} />
                      <p className="is-size-7 is-uppercase is-grey">{this.skin} skin</p>
                    </div>
                  </div>
                 { this.product.status === productStatus.onStock && (
                  <div className="has-text-centered my-medium" data-scroll data-scroll-speed="2">
                    <p className="is-size-4 my-small">Price: <b>€{this.product.price.toFixed(2)}</b></p>
                    <button className="button is-rounded is-primary has-text-black is-size-6 plausible-event-name=CTA+Order" onClick={this.placeOrder}>Order</button>
                    <p className="is-size-7 is-grey mt-medium">Price does not includes VAT, shipping or custom/duty
                      costs.<br/>Read more about additional charges <a href="/shipping">here</a>.</p>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="section product-benefits">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-xlarge" data-scroll data-scroll-speed="0">Easy tuning
                    mechanism</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">Tune your frame drum to the desired pitch simply by changing the air pressure with the provided pump.</p>
                  <img src={imageProductTuning} className="rounded-transparent square" alt="Easy tuning
                    mechanism" />
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-large" data-scroll data-scroll-speed="0">Steam bent & manually carved frame</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">The solid wooden frame that is steam-bent and manually carved ensures perfect and ergonomic holding while playing.</p>
                  <img src={imageProductCraft} className="rounded-transparent square" alt="Perfectly round & ergonomic" />
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-large" data-scroll data-scroll-speed="0">In harmony with
                    nature</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">By harvesting materials right from the source, we create perfect instruments without making an impact on nature.</p>
                  <img src={imageProductNature} className="rounded-transparent square" alt="In harmony with nature" />
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-large" data-scroll data-scroll-speed="0">Built to last & sound perfect</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">Played & tested in various conditions and stages around the world, it sounds perfect while played live and when faced with a studio mic.</p>
                  <img src={imageProductSound} className="rounded-transparent square" alt="Built to last & sound perfect" />
                </div>
              </div>
            </div>
          </section>

          <section className="section in-the-box">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-medium" data-scroll data-scroll-speed="1">What's in the box</h3>
                </div>
              </div>
              <div className="columns is-centered mb-medium">
                <div className="column is-10 has-text-centered">
                  <div className="columns">
                    <div className="column is-4">
                      <img src={imageBoxDrum} width="250" height="250" className="rounded-transparent my-medium" alt="Frame drum" />
                      <p className="is-size-6"><b>Frame drum</b><br />Obviously :)</p>
                    </div>
                    <div className="column is-4">
                      <img src={imageBoxBag} width="250" height="250" className="rounded-transparent my-medium" alt="Frame drum" />
                      <p className="is-size-6"><b>Weather resistant bag</b><br />for drum transportation</p>
                    </div>
                    <div className="column is-4">
                      <img src={imageBoxPump} width="250" height="250" className="rounded-transparent my-medium" alt="Frame drum" />
                      <p className="is-size-6"><b>Mini air pump</b><br />for easy tuning</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">Your frame drum is carefully wrapped with recycled paper and packed in a cardboard box before being shipped with a credible shipping service.</p>
                </div>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mt-xlarge mb-large" data-scroll data-scroll-speed="0">Looking for something else?</h3>
                  <div className="home-customize" data-scroll data-scroll-speed="1">
                    <img src={imageCustomWood} width="103" height="163" alt="" />
                    <img src={imagePlus} width="40" height="42" className="mx-large my-large" style={{'opacity': 0.3}} alt="" />
                    <img src={imageCustomLeather} width="139" height="164" alt="" />
                    <img src={imagePlus} width="40" height="42" className="mx-large my-large" style={{'opacity': 0.3}} alt="" />
                    <img src={imageCustomPattern} width="131" height="131" alt="" />
                  </div>
                  <p className="is-size-5 is-relative mt-large mb-large" data-scroll data-scroll-speed="2">Do you want your frame drum to be built by order and customized to your needs? No worries, just follow our lead.</p>
                  <a href="/custom"
                     className="button is-rounded is-primary has-text-black is-size-6 mb-xlarge plausible-event-name=CTA+Customize+drum"
                     data-scroll data-scroll-speed="4">Create your frame drum</a>
                </div>
              </div>
            </div>
          </section>

          <Footer/>
        </div>
          </div>
        </LocomotiveScrollProvider>
    );
  }
}

export default withRouter(ContentProduct);
