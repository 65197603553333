import React, { createRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import withRouter from './WithRouter';
import imageProductBearing from '../assets/img/product-bearingedge.jpg';
import imageProductRopes from '../assets/img/product-ropes.jpg';
import imageProductRubber from '../assets/img/product-rubberfoot.jpg';
import imageProductLsize from '../assets/img/product-Lsize.jpg';
import imageProductXLsize from '../assets/img/product-XLsize.jpg';
import imageProductFinish from '../assets/img/product-finish.jpg';
import imageProductSound from '../assets/img/product-djembe-sound.jpg';
import Footer from './Footer';
import {woodImages, skinImages, productStatus} from '../const';
import { Helmet } from 'react-helmet-async';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

class ContentProductDjembe extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 'open': false, 'audioPlay': false, 'mainImage': true, 'openVideo': false };

    this.containerRef = createRef();

    this.id = props.params.id;
    this.productIndex = props.productsDjembe.findIndex(product => product.sn === this.id);

    this.product = null;
    if (!isNaN(this.productIndex)) {
      this.product = props.productsDjembe[this.productIndex];
    }

    this.wood = this.product.wood.charAt(0).toUpperCase() + this.product.wood.slice(1);
    this.skin = this.product.skin.charAt(0).toUpperCase() + this.product.skin.slice(1);

    this.imageCount = this.product.images.length;
    this.mainImage = this.product.mainImageUrl;

    this.audios = {
      'audio': new Audio(this.product.audioUrl),
    };

    this.videoUrl = this.product.videoUrl;
    this.videoThumb = this.product.videoThumb;

    this.currentlyPlaying = null;

    this.placeOrder = this.placeOrder.bind(this)
  }

  placeOrder(event) {
    event.stopPropagation();
    // redirect to the order form  
    this.props.navigate('/order', {'state': {'product': this.product}});
  }

  togglePlay(audioName) {
    const audioState = `${audioName}Play`;
    this.setState({[audioState]: !this.state[audioState]}, () => {
      this.state[audioState] ? this.play(audioName) : this.pause(audioName);
    });
  }

  play(name){
    if (this.currentlyPlaying) {
      this.setState({[`${this.currentlyPlaying}Play`]: false}, () => {
        this.audios[this.currentlyPlaying].pause();
        this.audios[name].currentTime = 0;
        this.audios[name].play();
        this.currentlyPlaying = name;
      });
    } else {
      this.audios[name].currentTime = 0;
      this.audios[name].play();
      this.currentlyPlaying = name;
    }
  };

  pause(name){
    this.currentlyPlaying = null;
    this.audios[name].pause();
  };

  render()
  {
    return (
        <LocomotiveScrollProvider
            options={
              {
                smooth: true,
              }
            }
            watch={[]}
            containerRef={this.containerRef}
        >
          <Helmet>
            <title>Brainframe | Djembe drum {this.product.name}</title>
            <meta name="title" content={this.product.name}></meta>
            <meta name="description" content={this.product.descriptionLong}></meta>
          </Helmet>
          <div data-scroll-container ref={this.containerRef}>
        <div data-scroll-section>

          <section className="section">
            <div className="container">
              <div className="columns is-centered is-multiline mt-xlarge">
                <div className="column is-6">
                  <div className="product-visual is-relative has-text-centered">
                    <div style={{display: 'block'}} onClick={() => this.setState({'open': true})}>
                      <img src={this.mainImage}
                           className="is-relative is-inline-block reel" alt={this.product.name}
                      />
                      <button className="button is-rounded">Click to view more</button>
                    </div>
                    <Lightbox
                        open={this.state.open}
                        close={() => this.setState({'open': false})}
                        slides={this.product.images.map((image) => {
                          return { src: image, type: 'image', altTag: this.product.name }
                        })}
                    />
                  </div>

                  <div className="has-text-centered product-audio mt-small" data-scroll data-scroll-speed="2">
                    {this.state.audioPlay
                          ? <button className="button is-rounded is-primary is-outlined is-size-7 mr-small my-small is-playing audio-button" onClick={() => this.togglePlay('audio')}>Audio sample</button>
                          : <button className="button is-rounded is-primary is-outlined is-size-7 mr-small my-small  audio-button" onClick={() => this.togglePlay('audio')}>Audio sample</button>}
                  </div>
                </div>
                <div className="column is-6 product-info has-text-centered">
                  <h3 className="title is-size-4 mb-small" data-scroll data-scroll-speed="1">{this.product.name}</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">{this.product.descriptionLong}</p>

                  <div
                      className="product-info-specs is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-space-around is-align-items-flex-end p-medium mt-medium"
                      data-scroll data-scroll-speed="1">
                    <div className="has-text-centered">
                      <p className="is-size-5 leading-tight pb-small">{this.product.dimensions}</p>
                      <p className="is-size-7 is-uppercase is-grey">cm</p>
                    </div>
                    <div className="has-text-centered">
                      <p className="is-size-5 leading-tight pb-small">{this.product.weight}</p>
                      <p className="is-size-7 is-uppercase is-grey">kg</p>
                    </div>
                    <div className="has-text-centered">
                      <img src={woodImages[this.product.wood]} className="square rounded-opaque" alt={this.wood} />
                      <p className="is-size-7 is-uppercase is-grey">{this.wood} wood</p>
                    </div>
                    <div className="has-text-centered">
                      <img src={skinImages[this.product.skin]} className="square rounded-opaque" alt={this.skin.slice(6)} />
                      <p className="is-size-7 is-uppercase is-grey">{this.skin.slice(6)} skin</p>
                    </div>
                  </div>
                 { this.product.status === productStatus.onStock && (
                  <div className="has-text-centered my-medium" data-scroll data-scroll-speed="2">
                    <p className="is-size-4 my-small">Price: <b>€{this.product.price.toFixed(2)}</b></p>
                    <button className="button is-rounded is-primary has-text-black is-size-6 plausible-event-name=CTA+Order" onClick={this.placeOrder}>Order</button>
                    <p className="is-size-7 is-grey mt-medium">Price does not includes VAT, shipping or custom/duty
                      costs.<br/>Read more about additional charges <a href="/shipping">here</a>.</p>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="section product-benefits">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-12 has-text-centered" data-scroll data-scroll-speed="2">
                  <h3 className="title is-size-4 mb-medium">See it in action</h3>
                  <div className="product-video" onClick={() => {
                    this.setState({'openVideo': true});
                    if (this.state.audioPlay) {
                      this.togglePlay('audio');
                    }
                  }}>
                    <img src={this.videoThumb || this.mainImage}
                         className="is-relative is-inline-block reel" alt={this.product.name}
                    />
                  </div>
                  <Lightbox
                      open={this.state.openVideo}
                      close={() => this.setState({'openVideo': false})}
                      slides={[
                        {
                          type: "youtube",
                          width: 1280,
                          height: 720,
                          src: this.videoUrl
                        }
                      ]}
                      render={{
                        buttonPrev: () => null,
                        buttonNext: () => null,
                        slide: ({ slide, rect }) =>
                            slide.type === "youtube" ? (
                                <iframe
                                    width={Math.min(slide.width, rect.width, (slide.width * rect.height) / slide.height)}
                                    height={Math.min(slide.height, rect.height, (slide.height * rect.width) / slide.width)}
                                    src={slide.src}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            ) : null
                      }}
                  />
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-xlarge" data-scroll data-scroll-speed="0">Bearing edge</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">A rounded bearing edge distributes the impact of playing more evenly across the hand, encourages proper hand technique, and reduces the risk of strain injuries, leading to a more enjoyable and productive playing experience.</p>
                  <img src={imageProductBearing} className="rounded-transparent square" alt="Bearing edge" />
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-large" data-scroll data-scroll-speed="0">High-quality pre-stretched polyester ropes</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">With a prolonged lifespan, and more consistent and stable tuning over time, our high-quality ropes maintain the desired pitch of the drum during extended playing sessions or in varying environmental conditions.</p>
                  <img src={imageProductRopes} className="rounded-transparent square" alt="High-quality pre-stretched polyester ropes" />
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-large" data-scroll data-scroll-speed="0">Rubber foot</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">Rubber feet safeguard both the djembe and its resting surface, providing cushioning to prevent sliding and minimizing unwanted vibrations for improved sound quality. They also shield against scratches and dents, ensuring lasting protection for both drum and floor.</p>
                  <img src={imageProductRubber} className="rounded-transparent square" alt="Rubber foot" />
                </div>
              </div>
              
              {this.product.size === 'L'
                ? <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-large" data-scroll data-scroll-speed="0">Standard L-size Djembe shell</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">L-size djembe drums generally produce higher-pitched tones due to limited resonance space, resulting in a focused sound with sharper attack. They offer quicker response times and shorter decay, ideal for fast-paced rhythms and solo performances.</p>
                  <img src={imageProductLsize} className="rounded-transparent square" alt="Standard L-size Djembe shell" />
                </div>
              </div>
                : <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-large" data-scroll data-scroll-speed="0">Large XL-size shell</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">Larger djembe shells produce deeper, resonant tones with fuller bass due to increased air volume. They offer longer sustain for a lush sound and greater projection, making them ideal for ensemble or outdoor performances.</p>
                  <img src={imageProductXLsize} className="rounded-transparent square" alt="Large XL-size shell" />
                </div>
              </div>}

              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-large" data-scroll data-scroll-speed="0">Natural wood finish & protection</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">We use top-notch woodcare solutions crafted from premium natural ingredients, offering superb protection against wear, moisture, and stains, while also accentuating the inherent beauty of wood grain.</p>
                  <img src={imageProductFinish} className="rounded-transparent square" alt="Natural wood finish & protection" />
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-large" data-scroll data-scroll-speed="0">Built to last & sound perfect</h3>
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">Played & tested in various conditions and stages around the world, it sounds perfect while played live and when faced with a studio mic.</p>
                  <img src={imageProductSound} className="rounded-transparent square" alt="Built to last & sound perfect" />
                </div>
              </div>
            </div>
          </section>
{/*
          <section className="section in-the-box">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mb-medium mt-medium" data-scroll data-scroll-speed="1">What's in the box</h3>
                </div>
              </div>
              <div className="columns is-centered mb-medium">
                <div className="column is-6 has-text-centered">
                  <div className="columns">
                    <div className="column is-6">
                      <img src={imageBoxDrum} width="250" height="250" className="rounded-transparent my-medium" alt="Frame drum" />
                      <p className="is-size-6"><b>Djembe drum</b><br />Obviously :)</p>
                    </div>
                    <div className="column is-6">
                      <img src={imageBoxBag} width="250" height="250" className="rounded-transparent my-medium" alt="Frame drum" />
                      <p className="is-size-6"><b>Weather resistant bag</b><br />for drum transportation</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <p className="is-size-5 is-relative" data-scroll data-scroll-speed="1">Your djembe drum will be carefully wrapped with recycled paper and packed in a cardboard box before being shipped with a credible shipping service.</p>
                </div>
              </div>
            </div>
          </section>
*/} 
          <section className="section">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mt-xlarge mb-large" data-scroll data-scroll-speed="0">Looking for something else?</h3>
                  <p className="is-size-5 is-relative mt-large mb-large" data-scroll data-scroll-speed="2">Take a look at our selection of frame drums. It’s not djembe, but it sounds great.</p>
                  <a href="/frame-drums"
                     className="button is-rounded is-primary has-text-black is-size-6 mb-xlarge plausible-event-name=CTA+Customize+drum"
                     data-scroll data-scroll-speed="4">View frame drums</a>
                </div>
              </div>
            </div>
          </section>

          <Footer/>
        </div>
          </div>
        </LocomotiveScrollProvider>
    );
  }
}

export default withRouter(ContentProductDjembe);
