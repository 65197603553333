import React from 'react';
import ProductListItem from './ProductListItem';

export default class ProductList extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-3"></div>
          <div className="column is-6 has-text-centered">
            <h3 className="title is-size-4 mt-xlarge mb-small" data-scroll data-scroll-speed="1">Choose your frame drum</h3>
          </div>
          <div className="column is-3"></div>

          {this.props.products.map((product) =>
                <ProductListItem key={product.name} id= {product.name} product={product}/>
          )}
        </div>
      </div>
    );
  }
}