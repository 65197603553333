import React, { createRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';

export default class ContentLegal extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = createRef();
    }

    render() {
        return (
            <LocomotiveScrollProvider
                options={
                    {
                        smooth: true,
                    }
                }
                watch={[]}
                containerRef={this.containerRef}
            >
                <div data-scroll-container ref={this.containerRef}>
                <Helmet>
                    <title>Brainframe | Legal notices</title>
                    <meta name="title" content="Brainframe | Legal notices"></meta>
                    <meta name="description" content=""></meta>
                </Helmet>
            <div>
            <section className="section mb-xlarge">
            <div className="container">
              <div className="columns is-centered is-multiline">
                <div className="column is-8">
                  <h3 className="title is-size-4 mt-xlarge mb-medium has-text-centered" data-scroll data-scroll-speed="1">Company info</h3>
                  <p className="is-size-5 mb-small has-text-centered">Brainframe d.o.o.<br />Slavka Batušića 39, HR-10090 Zagreb<br />VAT ID: HR74770316141<br />IBAN: HR3824020061100959820<br />SWIFT: ESBCHR22</p>
                  <p className="is-size-5 mb-small mt-large">Brainframe d.o.o. owns the intellectual property of the brainframedrums.com website and the right to display all the elements of it. Everything on brainframedrums.com, including video, images, graphics and text, is protected by worldwide copyright as well as other proprietary laws and treaty provisions. Consequently, total or partial reproduction of the elements contained on the brainframedrums.com website, the use of such elements and the fact to make them available to third parties are strictly prohibited. Please contact us for information about availability of materials used on the brainframedrums.com website.</p>
                  
                  <h3 className="title is-size-4 mt-xlarge mb-medium has-text-centered" data-scroll data-scroll-speed="1">Prices, VAT & duties</h3>
                  <p className="is-size-5 mb-small">All product prices includes 25% Croatian VAT. If the Customer is resident outside the European Union the 25% VAT tax will not be calculated in total price. The Customer is responsible for any applicable taxes and custom fees according to country’s policy.</p>
                  <p className="is-size-5 mb-small">Brainframe d.o.o. agrees to pay all costs and suffer any potential losses of moving goods sold to a specific location. Using a DAP (delivered-at-place) agreement, the Customer is responsible for paying import duties and any applicable taxes, including clearance and local taxes, once the shipment has arrived at the specified destination. Customs duties vary by country and we advise checking the specifics for your own address. From our experience and based on the Duty Calculator app, for most countries we ship to your consignment will not need to be cleared, as it is delivered from the EU.</p>
                  <p className="is-size-5 mb-small">All prices on brainframedrums.com website are expressed in Euros (EUR). The prices of the products do not include shipping costs, which will be calculated and stated in proforma invoice, along with total costs and sent to Customer via e-mail.</p>

                  <h3 className="title is-size-4 mt-xlarge mb-medium has-text-centered" data-scroll data-scroll-speed="1">Warranty & return policy</h3>
                  <p className="is-size-5 mb-small">All product prices includes 25% Croatian VAT. If the Customer is resident outside the European Union the 25% VAT tax will not be calculated in total price. The Customer is responsible for any applicable taxes and custom fees according to country’s policy.</p>
                  <p className="is-size-5 mb-small">Brainframe d.o.o. agrees to pay all costs and suffer any potential losses of moving goods sold to a specific location. Using a DAP (delivered-at-place) agreement, the Customer is responsible for paying import duties and any applicable taxes, including clearance and local taxes, once the shipment has arrived at the specified destination. Customs duties vary by country and we advise checking the specifics for your own address. From our experience and based on the Duty Calculator app, for most countries we ship to your consignment will not need to be cleared, as it is delivered from the EU.</p>
                </div>
              </div>
            </div>
          </section>
        
        
          <Footer />
        </div>
                </div>
            </LocomotiveScrollProvider>
        );
    }
}