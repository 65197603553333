import React, { createRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';

export default class ContentMusicTherapy extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = createRef();
    }

    render() {
        return (
            <LocomotiveScrollProvider
                options={
                    {
                        smooth: true,
                    }
                }
                watch={[]}
                containerRef={this.containerRef}
            >
                <div data-scroll-container ref={this.containerRef}>
                <Helmet>
                    <title>Brainframe | Music Therapy</title>
                    <meta name="title" content="Brainframe | Music Therapy"></meta>
                    <meta name="description" content=""></meta>
                </Helmet>
            <div>
            <section className="section mb-xlarge">
            <div className="container">
              <div className="columns is-centered is-multiline">
                <div className="column is-8">
                  <h3 className="title is-size-4 mt-xlarge mb-medium has-text-centered" data-scroll data-scroll-speed="1">Music therapy</h3>
                </div>
              </div>
            </div>
          </section>
        
        
          <Footer />
        </div>
                </div>
            </LocomotiveScrollProvider>
        );
    }
}