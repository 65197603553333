import imageWoodAsh from './assets/img/wood-ash.png';
import imageWoodCherry from './assets/img/wood-cherry.png';
import imageWoodMaple from './assets/img/wood-maple.png';
import imageWoodOak from './assets/img/wood-oak.png';
import imageWoodWalnut from './assets/img/wood-walnut.png';
import imageWoodLenke from './assets/img/wood-lenke.png';
import imageWoodDugura from './assets/img/wood-dugura.png';
import imageWoodGele from './assets/img/wood-gele.png';
import imageWoodKhadi from './assets/img/wood-khadi.png';
import imageWoodDjala from './assets/img/wood-djala.png';
import imageSkinCalf from './assets/img/skin-calf.png';
import imageSkinDeer from './assets/img/skin-deer.png';
import imageSkinGoat from './assets/img/skin-goat.png';
import imageSkinHorse from './assets/img/skin-horse.png';
import imageSkinSheep from './assets/img/skin-sheep.png';
import imageSkinDjembeCalf from './assets/img/skin-djembe-calf.png';
import imageSkinDjembeDeer from './assets/img/skin-djembe-deer.png';
import imageSkinDjembeGoat from './assets/img/skin-djembe-goat.png';

export const woodImages = Object.freeze({
    ash: imageWoodAsh,
    cherry: imageWoodCherry,
    maple: imageWoodMaple,
    oak: imageWoodOak,
    walnut: imageWoodWalnut,
    Lenke: imageWoodLenke,
    Dugura: imageWoodDugura,
    Gele: imageWoodGele,
    Khadi: imageWoodKhadi,
    Djala: imageWoodDjala,
});

export const skinImages = Object.freeze({
    calf: imageSkinCalf,
    deer: imageSkinDeer,
    goat: imageSkinGoat,
    horse: imageSkinHorse,
    sheep: imageSkinSheep,
    djembecalf: imageSkinDjembeCalf,
    djembedeer: imageSkinDjembeDeer,
    djembegoat: imageSkinDjembeGoat,
});

export const productStatus = Object.freeze({
    onStock: 'On stock',
    sold: 'Sold'
}) 