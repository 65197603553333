const productsDjembe = [
    {
        'sn': 'BD0016',
        'name': 'BD0016',
        'wood': 'Gele',
        'skin': 'djembegoat',
        'size': 'L',
        'dimensions': '34x62',
        'weight': '12,1',
        'descriptionShort': 'L size gele wood djembe with a thick goat skin',
        'price': 520,
        'status': 'On stock',
        'descriptionLong': 'This L-size guele wood djembe with a thick Malian goat skin delivers warm, bright sound, featuring crisp highs, defined midrange tones, and a balanced spectrum with good resonance, ensuring effective projection.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0016/BD0016.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0016/BD0016_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0016/BD0016_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0016/BD0016_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0016/BD0016_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0016/BD0016_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0016/BD0016_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0016/BD0016.mp3',
        'videoUrl': 'https://www.youtube.com/embed/4C21GPeXPCk',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0016/BD0016_video.jpg',
    },
    {
        'sn': 'BD0015',
        'name': 'BD0015',
        'wood': 'Gele',
        'skin': 'djembegoat',
        'size': 'L',
        'dimensions': '33x60',
        'weight': '12,4',
        'descriptionShort': 'L size gele wood djembe with a thick goat skin',
        'price': 520,
        'status': 'On stock',
        'descriptionLong': 'This L-size gele wood djembe with a thick Malian goat skin delivers warm, bright sound, featuring crisp highs, defined midrange tones, and a balanced spectrum with good resonance, ensuring effective projection.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0015/BD0015.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0015/BD0015_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0015/BD0015_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0015/BD0015_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0015/BD0015_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0015/BD0015_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0015/BD0015_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0015/BD0015.mp3',
        'videoUrl': 'https://www.youtube.com/embed/hLlQMz_Pa2M',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0015/BD0015_video.jpg',
    },
    {
        'sn': 'BD0014',
        'name': 'BD0014',
        'wood': 'Gele',
        'skin': 'djembecalf',
        'size': 'XL',
        'dimensions': '38x64',
        'weight': '15,9',
        'descriptionShort': 'XL size gele wood djembe with a thick calf skin',
        'price': 700,
        'status': 'Sold',
        'descriptionLong': 'This XL-size gele wood djembe with a thick Pakistani calf skin delivers a clear, crisp sound with bright, vibrant tones and excellent articulation, making it ideal for intricate rhythms and dynamic playing styles.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0014/BD0014.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0014/BD0014_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0014/BD0014_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0014/BD0014_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0014/BD0014_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0014/BD0014_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0014/BD0014_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0014/BD0014.mp3',
        'videoUrl': 'https://www.youtube.com/embed/oZ30LUtJFDM',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0014/BD0014_video.jpg',
    },
    {
        'sn': 'BD0012',
        'name': 'BD0012',
        'wood': 'Khadi',
        'skin': 'djembegoat',
        'size': 'L',
        'dimensions': '31x61',
        'weight': '11,6',
        'descriptionShort': 'L size khadi wood djembe with a thick goat skin',
        'price': 360,
        'status': 'Sold',
        'descriptionLong': 'This L-size khadi wood djembe with a thick Malian goat skin delivers warm, bright sound, featuring crisp highs, defined midrange tones, and a balanced spectrum with good resonance, ensuring effective projection.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0012/BD0012.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0012/BD0012_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0012/BD0012_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0012/BD0012_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0012/BD0012_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0012/BD0012_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0012/BD0012_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0012/BD0012.mp3',
        'videoUrl': 'https://www.youtube.com/embed/Fq68XzKGif0',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0012/BD0012_video.jpg',
    },
    {
        'sn': 'BD0011',
        'name': 'BD0011',
        'wood': 'Dugura',
        'skin': 'djembedeer',
        'size': 'XL',
        'dimensions': '38x61',
        'weight': '12',
        'descriptionShort': 'XL size dugura wood djembe with very thick deer skin',
        'price': 620,
        'status': 'On stock',
        'descriptionLong': 'This XL-size dugura wood djembe with a very thick Croatian deer skin offers a mellow, rich sound characterized by smooth, rounded tones and a softer attack, resulting in a full-bodied resonance that is both lush and inviting.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0011/BD0011.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0011/BD0011_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0011/BD0011_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0011/BD0011_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0011/BD0011_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0011/BD0011_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0011/BD0011_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0011/BD0011.mp3',
        'videoUrl': 'https://www.youtube.com/embed/QQ_UelLY7K0',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0011/BD0011_video.jpg',
    },
    {
        'sn': 'BD0010',
        'name': 'BD0010',
        'wood': 'Lenke',
        'skin': 'djembegoat',
        'size': 'L',
        'dimensions': '34x62',
        'weight': '10,8',
        'descriptionShort': 'L size lenke wood djembe with a thick goat skin',
        'price': 420,
        'status': 'On stock',
        'descriptionLong': 'This L-size lenke wood djembe with a thick Malian goat skin delivers warm, bright sound, featuring crisp highs, defined midrange tones, and a balanced spectrum with good resonance, ensuring effective projection.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0010/BD0010.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0010/BD0010_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0010/BD0010_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0010/BD0010_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0010/BD0010_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0010/BD0010_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0010/BD0010_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0010/BD0010.mp3',
        'videoUrl': 'https://www.youtube.com/embed/ZlA9wwPcogc',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0010/BD0010_video.jpg',
    },
    {
        'sn': 'BD0009',
        'name': 'BD0009',
        'wood': 'Lenke',
        'skin': 'djembegoat',
        'size': 'XL',
        'dimensions': '36x62',
        'weight': '11,7',
        'descriptionShort': 'XL size lenke wood djembe with a very thick goat skin',
        'price': 520,
        'status': 'Sold',
        'descriptionLong': 'This XL-size lenke wood djembe with a very thick Malian goat skin produces deeper, more resonant tones with lower frequencies, attributed to the greater air volume inside the drum, resulting in a fuller, more robust sound with a deeper bass response.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0009/BD0009.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0009/BD0009_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0009/BD0009_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0009/BD0009_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0009/BD0009_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0009/BD0009_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0009/BD0009_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0009/BD0009.mp3',
        'videoUrl': 'https://www.youtube.com/embed/t40WAizUTYk',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0009/BD0009_video.jpg',
    },
    {
        'sn': 'BD0008',
        'name': 'BD0008',
        'wood': 'Lenke',
        'skin': 'djembegoat',
        'size': 'L',
        'dimensions': '32x61',
        'weight': '10,9',
        'descriptionShort': 'L size lenke wood djembe with a thick goat skin',
        'price': 460,
        'status': 'On stock',
        'descriptionLong': 'This L-size lenke wood djembe with a thick Malian goat skin delivers warm, bright sound, featuring crisp highs, defined midrange tones, and a balanced spectrum with good resonance, ensuring effective projection.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0008/BD0008.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0008/BD0008_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0008/BD0008_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0008/BD0008_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0008/BD0008_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0008/BD0008_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0008/BD0008_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0008/BD0008.mp3',
        'videoUrl': 'https://www.youtube.com/embed/ekSXuZUb92E',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0008/BD0008_video.jpg',
    },
    {
        'sn': 'BD0007',
        'name': 'BD0007',
        'wood': 'Gele',
        'skin': 'djembedeer',
        'size': 'L',
        'dimensions': '32x60',
        'weight': '12,3',
        'descriptionShort': 'L size gele wood djembe with a thick deer skin',
        'price': 500,
        'status': 'On stock',
        'descriptionLong': 'This L-size gele wood djembe with a thick Croatian deer skin offers a mellow, rich sound characterized by smooth, rounded tones and a softer attack, resulting in a full-bodied resonance that is both lush and inviting.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0007/BD0007.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0007/BD0007_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0007/BD0007_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0007/BD0007_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0007/BD0007_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0007/BD0007_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0007/BD0007_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0007/BD0007.mp3',
        'videoUrl': 'https://www.youtube.com/embed/6-MXrHC92yA',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0007/BD0007_video.jpg',
    },
    {
        'sn': 'BD0006',
        'name': 'BD0006',
        'wood': 'Khadi',
        'skin': 'djembegoat',
        'size': 'L',
        'dimensions': '34x61',
        'weight': '10,4',
        'descriptionShort': 'L size khadi wood djembe with a thick goat skin',
        'price': 480,
        'status': 'On stock',
        'descriptionLong': 'This L-size khadi wood djembe with a thick Malian goat skin delivers warm, bright sound, featuring crisp highs, defined midrange tones, and a balanced spectrum with good resonance, ensuring effective projection.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0006/BD0006.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0006/BD0006_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0006/BD0006_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0006/BD0006_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0006/BD0006_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0006/BD0006_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0006/BD0006_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0006/BD0006.mp3',
        'videoUrl': 'https://www.youtube.com/embed/80wdH5yfpzE',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0006/BD0006_video.jpg',
    },
    {
        'sn': 'BD0005',
        'name': 'BD0005',
        'wood': 'Djala',
        'skin': 'djembegoat',
        'size': 'L',
        'dimensions': '34x62',
        'weight': '11',
        'descriptionShort': 'L size djala wood djembe with a thick goat skin',
        'price': 440,
        'status': 'On stock',
        'descriptionLong': 'This L-size djala wood djembe with a thick Malian goat skin delivers warm, bright sound, featuring crisp highs, defined midrange tones, and a balanced spectrum with good resonance, ensuring effective projection.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0005/BD0005.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0005/BD0005_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0005/BD0005_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0005/BD0005_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0005/BD0005_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0005/BD0005_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0005/BD0005_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0005/BD0005.mp3',
        'videoUrl': 'https://www.youtube.com/embed/6pQVKFGA-KI',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0005/BD0005_video.jpg',
    },
    {
        'sn': 'BD0004',
        'name': 'BD0004',
        'wood': 'Khadi',
        'skin': 'djembegoat',
        'size': 'L',
        'dimensions': '34x64',
        'weight': '13,1',
        'descriptionShort': 'L size khadi wood djembe with a thick goat skin',
        'price': 420,
        'status': 'Sold',
        'descriptionLong': 'This L-size khadi wood djembe with a thick Malian goat skin delivers warm, bright sound, featuring crisp highs, defined midrange tones, and a balanced spectrum with good resonance, ensuring effective projection.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0004/BD0004.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0004/BD0004_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0004/BD0004_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0004/BD0004_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0004/BD0004_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0004/BD0004_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0004/BD0004_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0004/BD0004.mp3',
        'videoUrl': 'https://www.youtube.com/embed/rZuQJO3kt0Q',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0004/BD0004_video.jpg',
    },
    {
        'sn': 'BD0003',
        'name': 'BD0003',
        'wood': 'Dugura',
        'skin': 'djembegoat',
        'size': 'XL',
        'dimensions': '39x62',
        'weight': '11,5',
        'descriptionShort': 'XL size lenke wood djembe with a very thick goat skin',
        'price': 600,
        'status': 'On stock',
        'descriptionLong': 'This XL-size dugura wood djembe with a thick Malian goat skin produces deeper, more resonant tones with lower frequencies, attributed to the greater air volume inside the drum, resulting in a fuller, more robust sound with a deeper bass response.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0003/BD0003.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0003/BD0003_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0003/BD0003_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0003/BD0003_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0003/BD0003_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0003/BD0003_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0003/BD0003_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0003/BD0003.mp3',
        'videoUrl': 'https://www.youtube.com/embed/8LQRl4HlICo',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0003/BD0003_video.jpg',
    },
    {
        'sn': 'BD0002',
        'name': 'BD0002',
        'wood': 'Lenke',
        'skin': 'djembegoat',
        'size': 'XL',
        'dimensions': '35x62',
        'weight': '11,5',
        'descriptionShort': 'XL size lenke wood djembe with a thick goat skin',
        'price': 520,
        'status': 'On stock',
        'descriptionLong': 'This XL-size lenke wood djembe with a thick Malian goat skin delivers warm, bright sound, featuring crisp highs, defined midrange tones, and a balanced spectrum with good resonance, ensuring effective projection.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0002/BD0002.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0002/BD0002_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0002/BD0002_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0002/BD0002_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0002/BD0002_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0002/BD0002_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0002/BD0002_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0002/BD0002.mp3',
        'videoUrl': 'https://www.youtube.com/embed/iXdGxrQf2Cg',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0002/BD0002_video.jpg',
    },
    {
        'sn': 'BD0001',
        'name': 'BD0001',
        'wood': 'Khadi',
        'skin': 'djembegoat',
        'size': 'L',
        'dimensions': '33x62',
        'weight': '12,8',
        'descriptionShort': 'L size khadi wood djembe with very thick goat skin',
        'price': 480,
        'status': 'On stock',
        'descriptionLong': 'This L-size khadi wood djembe with a very thick Malian goat skin delivers warm, bright sound, featuring crisp highs, defined midrange tones, and a balanced spectrum with good resonance, ensuring effective projection.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0001/BD0001.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0001/BD0001_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0001/BD0001_1.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0001/BD0001_2.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0001/BD0001_3.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0001/BD0001_4.jpg',
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0001/BD0001_5.jpg',
        ],
        'audioUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0001/BD0001.mp3',
        'videoUrl': 'https://www.youtube.com/embed/Cqo9db1x_mo',
        'videoThumb': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BD0001/BD0001_video.jpg',
    },
];

export default productsDjembe;