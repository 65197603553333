import React, { createRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import imageSizes from '../assets/img/sizes.png';
import imageSkins from '../assets/img/skins.png';
import imageWood from '../assets/img/wood.png';
import imageCustomWood from '../assets/img/custom-wood.svg';
import imageCustomLeather from '../assets/img/custom-leather.svg';
import imageCustomPattern from '../assets/img/custom-pattern.svg';
import imagePlus from '../assets/img/plus.svg';

import Footer from './Footer';
import ProductList from './ProductList';
import { Helmet } from 'react-helmet-async';

export default class ContentDrums extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
  }
  render() {
    return (
        <LocomotiveScrollProvider
            options={
              {
                smooth: true,
              }
            }
            watch={[]}
            containerRef={this.containerRef}
        >
         <Helmet>
            <title>Brainframe | Frame drums</title>
            <meta name="title" content="Brainframe | Frame drums"></meta>
            <meta name="description" content="Our range of frame drums resemble the ancient traditional drums like Turkish Bendir, Syrian Mizhar or North African Tar, made from a solid, steam bent wood and naturally processed rawhides."></meta>
          </Helmet>
          <div data-scroll-container ref={this.containerRef}>
<div data-scroll-section>
  <section className="section">
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-8 has-text-centered">
          <h3 className="title is-size-4 mt-xlarge mb-large" data-scroll data-scroll-speed="0">Frame drums</h3>
          <p className="is-size-4 is-relative has-text-weight-light" data-scroll data-scroll-speed="2">Our range of frame drums resembles ancient traditional drums like Turkish Bendir, Syrian Mizhar, or North African Tar, made from solid, steam-bent wood and naturally processed skins. Each drum has an <u>innovative tuning system</u> that allows changing the pitch of a drum in seconds, simply by using a small air pump.</p>
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="columns is-centered is-multiline">
        <div className="column is-12 has-text-centered">
          <img src={imageSizes} width="337" height="138" data-scroll data-scroll-speed="1" className="drums-sizes" alt="Wide selection of dimensions" />
          <p className="is-size-6 is-grey mt-small" data-scroll data-scroll-speed="1">Wide selection of dimensions</p>
        </div>
        <div className="column is-12 has-text-centered">
          <img src={imageSkins} className="mt-medium" width="650" height="140" data-scroll data-scroll-speed="2" alt="Naturally processed drum skin" />
          <p className="is-size-6 is-grey mt-small" data-scroll data-scroll-speed="2">Naturally processed drum skin</p>
        </div>
        <div className="column is-12 has-text-centered">
          <img src={imageWood} className="mt-medium" width="650" height="140" data-scroll data-scroll-speed="3" alt="Locally grown walnut, maple, ash, oak and cherry" />
          <p className="is-size-6 is-grey mt-small" data-scroll data-scroll-speed="3">Locally grown walnut, maple, ash, oak and cherry</p>
        </div>
      </div>
    </div>
  </section>

  <section className="section">
  <ProductList products={this.props.products}/>

  </section>

  <section className="section">
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-6 has-text-centered">
          <h3 className="title is-size-4 mt-xlarge mb-large" data-scroll data-scroll-speed="0">Customize your frame drum</h3>
          <div className="home-customize" data-scroll data-scroll-speed="1">
            <img src={imageCustomWood} width="103" height="163" alt="" />
            <img src={imagePlus} width="40" height="42" className="mx-large my-large" style={{'opacity': 0.3}} alt="" />
            <img src={imageCustomLeather} width="139" height="164" alt="" />
            <img src={imagePlus} width="40" height="42" className="mx-large my-large" style={{'opacity': 0.3}} alt="" />
            <img src={imageCustomPattern} width="131" height="131" alt="" />
          </div>
          <p className="is-size-5 is-relative mt-large mb-large" data-scroll data-scroll-speed="2">Do you want your frame drum to be built by order and customised to your needs? No worries, just follow our lead.</p>
          <a href="/custom" className="button is-rounded is-primary has-text-black is-size-6 mb-xlarge plausible-event-name=CTA+Customize+drum" data-scroll data-scroll-speed="4">Create your frame drum</a>
        </div>
      </div>
    </div>
  </section>

  <Footer />
</div>
          </div>
        </LocomotiveScrollProvider>
   );
  }
}