import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    return (
        <WrappedComponent
            {...props}
            {...{ navigate }}
            {...{ params }}
            {...{ location }}
        />
    );
};

export default withRouter;