import React, { createRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import bulmaCollapsible from '@creativebulma/bulma-collapsible';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';

export default class ContentShipping extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
  }

  onclickBulma(e){
    const elementId = e.target.hash.slice(1);
    const bulmaCollapsibleElement = document.getElementById(elementId);
    if (bulmaCollapsibleElement) {
      new bulmaCollapsible(bulmaCollapsibleElement);

      if(bulmaCollapsibleElement.bulmaCollapsible('collapsed')){
        bulmaCollapsibleElement.bulmaCollapsible('expand');
      } else {
        bulmaCollapsibleElement.bulmaCollapsible('collapse');
      }
    }
  }

  render() {
    return (
        <LocomotiveScrollProvider
            options={
              {
                smooth: true,
              }
            }
            watch={[]}
            containerRef={this.containerRef}
        >
          <Helmet>
            <title>Brainframe | Shipping & FAQ</title>
            <meta name="title" content="Brainframe | Shipping and FAQ"></meta>
            <meta name="description" content="We know how important it is to receive your goods when you expect them, so we want to make sure you have all of the delivery information you'll need when placing an order with us."></meta>
          </Helmet>
          <div data-scroll-container ref={this.containerRef}>
      <div data-scroll-section>
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-6 has-text-centered">
                <h3 className="title is-size-4 mt-xlarge mb-large" data-scroll data-scroll-speed="0">From our workshop to your doorstep</h3>
                <p className="is-size-4 is-relative has-text-weight-light" data-scroll data-scroll-speed="3">We know how important it is to receive your goods when you expect them, so we want to make sure you have all of the delivery information you'll need when placing an order with us.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-6">
                <h3 className="title is-size-4 mt-large mb-medium has-text-centered" data-scroll data-scroll-speed="1">FAQ</h3>
                <ol id="faq" data-scroll data-scroll-speed="2">
                  <li className="is-size-5">
                    <h4 className="is-colored">What happens when I place an order?</h4>
                    <div id="faq-1" className="is-size-6">When you submit your order on our webpage we will send you a pro forma invoice to your email address with all the important information for finalizing your order, such as price breakdown, payment instructions, and delivery dates. Don’t worry, our Master Builder will personally take care of your order :) His usual response time is 24–48 hrs.</div>
                  </li>
                  <li className="is-size-5">
                    <h4 className="is-colored">Which payment options are available?</h4>
                    <div id="faq-2" className="is-size-6">At this moment, only bank transfers to our bank account are possible. You will get all payment information and instructions in your email after placing an order.</div>
                  </li>
                  <li className="is-size-5">
                    <h4 className="is-colored">What about tax, customs & import duties?</h4>
                    <div id="faq-3" className="is-size-6">All prices shown on the website are excluding VAT, duties & taxes. The final price sent in the pro forma invoice to your e-mail includes VAT and shipping costs, however; import duties differ from country to country and are calculated when the goods reach the destination country. We will provide you with a tracking number so you can track progress, but you will surely be contacted by local customs or courier service when and if needed.</div>
                  </li>
                  <li className="is-size-5">
                    <h4 className="is-colored">Is it possible to order more than one drum?</h4>
                    <div id="faq-4" className="is-size-6">Bulk orders are not possible via the website, but you can always place more than one order if you want to buy more than one drum. We will summarise all your orders in one pro forma invoice that will be sent to your e-mail address after ordering. Our usual response time is 24–48 hrs.</div>
                  </li>
                  <li className="is-size-5">
                    <h4 className="is-colored">What happens if the drum gets damaged in transport?</h4>
                    <div id="faq-5" className="is-size-6">Stop unpacking and follow the instructions delivered with your drum. If you need help, please contact us immediately by <a href="mailto:info@brainframedrums.com">e-mail</a> or <a href="tel:+385919749608">WhatsApp</a>.</div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
          </div>
        </LocomotiveScrollProvider>
    );
  }
}