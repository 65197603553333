import React from 'react';
import ProductListItemDjembe from './ProductListItemDjembe';

export default class ProductListDjembe extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-3"></div>
          <div className="column is-6 has-text-centered">
            <h3 className="title is-size-4 mt-xlarge mb-small" data-scroll data-scroll-speed="1">Choose your djembe drum</h3>
          </div>
          <div className="column is-3"></div>

          {this.props.productsDjembe.map((product) =>
                <ProductListItemDjembe key={product.name} id= {product.name} product={product}/>
          )}
        </div>
      </div>
    );
  }
}