import React from 'react';
import {Link} from "react-router-dom";
import imageEU from '../assets/img/eu-amblem-en.png';

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <div className="has-text-centered mt-medium" data-scroll data-scroll-speed="2">
                    <div className="columns">
                        <div className="column is-4 has-text-centered">
                            <div>
                                <h5 className="title is-size-7 mt-large mb-small">Social media</h5>
                                <a className="is-size-6 is-block" href="https://www.instagram.com/brainframedrums/" target="_blank" rel="noreferrer">Instagram</a>
                                <a className="is-size-6 is-block" href="https://www.facebook.com/brainframedrums" target="_blank" rel="noreferrer">Facebook</a>
                                <a className="is-size-6 is-block" href="https://www.youtube.com/@Brainframedrums" target="_blank" rel="noreferrer">Youtube</a>
                                <a className="is-size-6 is-block" href="https://www.pinterest.com/brainframedrums/_created" target="_blank" rel="noreferrer">Pinterest</a>
                            </div>
                        </div>
                        <div className="column is-4 has-text-centered">
                            <div>
                                <h5 className="title is-size-7 mt-large mb-small">Studio</h5>
                                <p className="is-size-6">Cebini 21A<br />10010 Zagreb<br />Croatia</p>
                            </div>
                        </div>
                        <div className="column is-4 has-text-centered">
                            <div>
                                <h5 className="title is-size-7 mt-large mb-small">Contact</h5>
                                <p className="is-size-6"><a href="mailto:info@brainframedrums.com">info@brainframedrums.com</a><br /><a href="tel:+385919749608">+385 91 9749 608</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="has-text-centered mt-xlarge"><img src={imageEU} width="250" height="62" alt="" /></div>
                    <p className="is-size-7 is-grey mt-xlarge">&copy; 2024. Brainframe d.o.o.<br />HQ: Slavka Batušića 39, 10090 Zagreb, Croatia<br /><Link to="/legal">Legal notices</Link></p>
                </div>
            </footer>
        );
    }
}