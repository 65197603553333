import React, { createRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import imageProcess1 from '../assets/img/process-1.jpg';
import imageProcess2 from '../assets/img/process-2.jpg';
import imageProcess3 from '../assets/img/process-3.jpg';
import imageProcess4 from '../assets/img/process-4.jpg';
import imageProcess5 from '../assets/img/process-5.jpg';

import Footer from './Footer';
import { Helmet } from 'react-helmet-async';

export default class ContentProcess extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = createRef();
    }

    render() {
        return (
            <LocomotiveScrollProvider
                options={
                    {
                        smooth: true,
                    }
                }
                watch={[]}
                containerRef={this.containerRef}
            >
                <Helmet>
                  <title>Brainframe | The process</title>
                  <meta name="title" content="Brainframe | The process"></meta>
                  <meta name="description" content="It took us years to find the right ingredients and develop special tools in order to make perfect instruments sustainable and environment-safe."></meta>
                </Helmet>
                <div data-scroll-container ref={this.containerRef}>
            <div data-scroll-section>
            <section className="section">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="title is-size-4 mt-xlarge mb-large" data-scroll data-scroll-speed="0">The process of making drums</h3>
                  <p className="is-size-4 is-relative has-text-weight-light" data-scroll data-scroll-speed="3">It took us years to find the right ingredients and develop special tools to make perfect instruments sustainable and environment-safe.</p>
                </div>
              </div>
            </div>
          </section>
        
          <section className="section">
            <div className="container">
        
              <div className="columns is-multiline">
                <div className="column is-7"></div>
                <div className="column is-5">
                    <img src={imageProcess1} className="process-image-right rounded-transparent square" alt="Research & development" />
                </div>
                <div className="column is-2"></div>
                <div className="column is-7">
                    <div className="overlap-with-image mb-xlarge">
                        <h4 className="title is-size-4" data-scroll data-scroll-speed="1">Research & development</h4>
                        <p className="is-size-6 indent-text" data-scroll data-scroll-speed="2">In the intricate process of drum making, each stage demands unique solutions, with steam bending standing out as a particularly challenging endeavor. Despite relying on established knowledge and physics principles, it's fraught with unpredictable variables, requiring mastery of subtle techniques for success. With precision and attention to detail, we craft visually stunning instruments with exceptional sound and playability.</p>
                    </div>
                </div>
                <div className="column is-3"></div>
              </div>
        
              <div className="columns is-multiline">
                <div className="column is-5">
                    <img src={imageProcess2} className="process-image-left rounded-transparent square" alt="Material selection" />
                </div>
                <div className="column is-7"></div>
                <div className="column is-3"></div>
                <div className="column is-7">
                    <div className="overlap-with-image has-text-right mb-xlarge">
                        <h4 className="title is-size-4" data-scroll data-scroll-speed="1">Material selection</h4>
                        <p className="is-size-6 indent-text" data-scroll data-scroll-speed="2">Most drum creation phases are done in-house, ensuring the highest quality for all components, from naturally de-haired drumheads to carefully selected wood types. Sustainability is our top priority, as we strive to minimize environmental impact by repurposing raw materials that would otherwise go to waste. We meticulously select indigenous wood for frame drums and adhere to traditional hardwood choices for djembe drums.</p>
                    </div>
                </div>
                <div className="column is-2"></div>
              </div>
        
              <div className="columns is-multiline">
                <div className="column is-7"></div>
                <div className="column is-5">
                    <img src={imageProcess3} className="process-image-right rounded-transparent square" alt="Skin preparation" />
                </div>
                <div className="column is-2"></div>
                <div className="column is-7">
                    <div className="overlap-with-image mb-xlarge">
                        <h4 className="title is-size-4" data-scroll data-scroll-speed="1">Skin preparation</h4>
                        <p className="is-size-6 indent-text" data-scroll data-scroll-speed="2">All drums in our selection are crafted from natural drumheads, each undergoing distinct processing methods tailored to its specific requirements. These methods include labor-intensive practices like manual defleshing and dehairing, as well as additional stretching on custom-built frames to refine the fibers before mounting them onto the drum. These traditional techniques are crucial for ensuring that the resulting membranes produce a melodic, pure, and tonally clear sound.</p>
                    </div>
                </div>
                <div className="column is-3"></div>
              </div>
        
              <div className="columns is-multiline">
                <div className="column is-5">
                    <img src={imageProcess4} className="process-image-left rounded-transparent square" alt="Hoop bending" />
                </div>
                <div className="column is-7"></div>
                <div className="column is-3"></div>
                <div className="column is-7">
                    <div className="overlap-with-image has-text-right mb-xlarge">
                        <h4 className="title is-size-4" data-scroll data-scroll-speed="1">Frame drum hoop bending</h4>
                        <p className="is-size-6 indent-text" data-scroll data-scroll-speed="2">After numerous failed attempts at manually bending hoops into full circles, resulting in injuries, we developed a specialized machine using hydraulic power for efficient bending. While our current bending process is efficient and safe, achieving successful bends with certain wood species remains a challenge.<br/><br/>Once dried, the bentwood is affixed to a hoop and undergoes further processing, primarily sanding and milling, before being ready for membrane mounting.</p>
                    </div>
                </div>
                <div className="column is-2"></div>
              </div>
        
              <div className="columns is-multiline">
                <div className="column is-7"></div>
                <div className="column is-5">
                    <img src={imageProcess5} className="process-image-right rounded-transparent square" alt="Drum creation" />
                </div>
                <div className="column is-2"></div>
                <div className="column is-7">
                    <div className="overlap-with-image mb-xlarge">
                        <h4 className="title is-size-4" data-scroll data-scroll-speed="1">Drum creation</h4>
                        <p className="is-size-6 indent-text" data-scroll data-scroll-speed="2">As shell and drumhead await their union, the alchemy of drum creation unfolds, marking the crescendo of a drum maker's journey. Here, painstaking labor transmutes into a masterpiece, as two exquisite components converge to create a symphony of unity — a vessel whose ancient melody echoes through the ages.<br/><br/>Each of our drums is meticulously tailored to elevate and evoke its ultimate voice, pleasing even the most discerning player.<br/><br/>Our drums are tested and endorsed by prominent musicians from Croatia, Slovenia, Austria, Germany, the United States, and beyond.</p>
                    </div>
                </div>
                <div className="column is-3"></div>
              </div>
              
            </div>
          </section>
        
          <section className="section">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-7 has-text-centered">
                  <h3 className="title is-size-4 mt-medium mb-medium" data-scroll data-scroll-speed="1">Choose your drum</h3>
                  <p className="is-size-5 is-relative mt-medium mb-medium" data-scroll data-scroll-speed="2">Discover available drums on our website and get yourself a perfect instrument for stage, studio, or therapy purposes.</p>
                  <div className="mb-xlarge" data-scroll data-scroll-speed="3">
                    <a className="button is-multiple is-rounded is-primary has-text-black is-size-6" href="/frame-drums">View frame drums</a>
                    <a className="button is-multiple is-rounded is-primary has-text-black is-size-6" href="/djembe-drums">View djembe drums</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        
        
          <Footer />
        </div>
                </div>
            </LocomotiveScrollProvider>
        );
    }
}