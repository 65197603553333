import React, { createRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import imageSizes from '../assets/img/sizes-djembe.png';
import imageSkins from '../assets/img/skins-djembe.png';
import imageWood from '../assets/img/wood-djembe.png';
import Footer from './Footer';
import ProductListDjembe from './ProductListDjembe';
import { Helmet } from 'react-helmet-async';

export default class ContentDjembe extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
  }
  render() {
    return (
        <LocomotiveScrollProvider
            options={
              {
                smooth: true,
              }
            }
            watch={[]}
            containerRef={this.containerRef}
        >
         <Helmet>
            <title>Brainframe | Djembe drums</title>
            <meta name="title" content="Brainframe | Djembe drums"></meta>
            <meta name="description" content="The djembe is often cited as one of the most popular drums in the world. Originating from West Africa, its versatility, distinctive sound, and the ease with which it can be integrated into various music genres have contributed to its widespread popularity across the globe."></meta>
          </Helmet>
          <div data-scroll-container ref={this.containerRef}>
<div data-scroll-section>
  <section className="section">
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-8 has-text-centered">
          <h3 className="title is-size-4 mt-xlarge mb-large" data-scroll data-scroll-speed="0">Djembe drums</h3>
          <p className="is-size-4 is-relative has-text-weight-light" data-scroll data-scroll-speed="2">The djembe is often cited as one of the most popular drums in the world. Originating from West Africa, its versatility, distinctive sound, and the ease with which it can be integrated into various music genres have contributed to its widespread popularity across the globe.</p>
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="columns is-centered is-multiline">
        <div className="column is-12 has-text-centered">
          <img src={imageSizes} width="337" height="138" data-scroll data-scroll-speed="1" className="drums-sizes" alt="Wide selection of dimensions and shapes" />
          <p className="is-size-6 is-grey mt-small" data-scroll data-scroll-speed="1">Standard to extra large shell size</p>
        </div>
        <div className="column is-12 has-text-centered">
          <img src={imageSkins} className="mt-medium" width="650" height="140" data-scroll data-scroll-speed="2" alt="Different skin varieties and thicknesses" />
          <p className="is-size-6 is-grey mt-small" data-scroll data-scroll-speed="2">Different skin varieties and thicknesses</p>
        </div>
        <div className="column is-12 has-text-centered">
          <img src={imageWood} className="mt-medium" width="650" height="140" data-scroll data-scroll-speed="3" alt="Best djembe woods: Lenke, Dugura, Gele, Khadi, Djalla, Melina" />
          <p className="is-size-6 is-grey mt-small" data-scroll data-scroll-speed="3">Best djembe woods: Lenke, Dugura, Gele, Khadi, Djala</p>
        </div>
      </div>
    </div>
  </section>

  <section className="section">
  <ProductListDjembe productsDjembe={this.props.productsDjembe}/>

  </section>

  <Footer />
</div>
          </div>
        </LocomotiveScrollProvider>
   );
  }
}