const products = [
    {
        'sn': 'BF0070',
        'name': 'BF0070',
        'wood': 'maple',
        'skin': 'deer',
        'diameterInch': 14,
        'diameterCm': 35,
        'descriptionShort': '14" maple frame with deer skin',
        'price': 455,
        'status': 'Sold',
        'descriptionLong': 'This frame drum is made from maple, which is perceived as one of the most popular wood species for drum shells. It creates balanced and warm sound with a decent level of highs and mid level tones and full low frequencies. Because of this balance between brightness and warmth of maple, it merges completely with membranes from very thin to thick, giving a drum overall pleasant voice character.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0070/BF0070.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0070/BF0070_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0070/BF0070_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0070/BF0070-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0070/BF0070-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0069',
        'name': 'BF0069',
        'wood': 'walnut',
        'skin': 'deer',
        'diameterInch': 14,
        'diameterCm': 35,
        'descriptionShort': '14" walnut frame with deer skin',
        'price': 415,
        'status': 'Sold',
        'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0069/BF0069.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0069/BF0069_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0069/BF0069_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0069/BF0069-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0069/BF0069-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0068',
        'name': 'BF0068',
        'wood': 'walnut',
        'skin': 'goat',
        'diameterInch': 16,
        'diameterCm': 40,
        'descriptionShort': '16" walnut frame with goat skin',
        'price': 415,
        'status': 'On stock',
        'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0068/BF0068.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0068/BF0068_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0068/BF0068_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0068/BF0068-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0068/BF0068-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0067',
        'name': 'BF0067',
        'wood': 'walnut',
        'skin': 'deer',
        'diameterInch': 14,
        'diameterCm': 35,
        'descriptionShort': '14" walnut frame with deer skin',
        'price': 415,
        'status': 'On stock',
        'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0067/BF0067.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0067/BF0067_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0067/BF0067_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0067/BF0067-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0067/BF0067-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0064',
        'name': 'BF0064',
        'wood': 'walnut',
        'skin': 'deer',
        'diameterInch': 18,
        'diameterCm': 45,
        'descriptionShort': '18" walnut frame with deer skin',
        'price': 455,
        'status': 'On stock',
        'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0064/BF0064.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0064/BF0064_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0064/BF0064_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0064/BF0064-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0064/BF0064-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0061',
        'name': 'BF0061',
        'wood': 'walnut',
        'skin': 'goat',
        'diameterInch': 18,
        'diameterCm': 45,
        'descriptionShort': '18" walnut frame with goat skin',
        'price': 435,
        'status': 'On stock',
        'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0061/BF0061.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0061/BF0061_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0061/BF0061_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0061/BF0061-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0061/BF0061-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0059',
        'name': 'BF0059',
        'wood': 'oak',
        'skin': 'goat',
        'diameterInch': 16,
        'diameterCm': 40,
        'descriptionShort': '16" oak frame with goat skin',
        'price': 415,
        'status': 'On stock',
        'descriptionLong': 'This oak made frame drum belongs to some of the  most powerful and solid, as well as playful woods we have worked with. With intelligence of its own, oak shell offers exceptionally warm sound with soft highs, good midrange, and fairly warm lows. It delivers fast projection with full-bodied presence sound, specifically with semi thick skins from our membranes range.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0059/BF0059.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0059/BF0059_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0059/BF0059_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0059/BF0059-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0059/BF0059-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0057',
        'name': 'BF0057',
        'wood': 'walnut',
        'skin': 'deer',
        'diameterInch': 16,
        'diameterCm': 40,
        'descriptionShort': '16" walnut frame with deer skin',
        'price': 435,
        'status': 'On stock',
        'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0057/BF0057.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0057/BF0057_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0057/BF0057_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0057/BF0057-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0057/BF0057-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0056',
        'name': 'BF0056',
        'wood': 'maple',
        'skin': 'deer',
        'diameterInch': 22,
        'diameterCm': 55,
        'descriptionShort': '22" maple frame with deer skin',
        'price': 615,
        'status': 'Sold',
        'descriptionLong': 'This frame drum is made from maple, which is perceived as one of the most popular wood species for drum shells. It creates balanced and warm sound with a decent level of highs and mid level tones and full low frequencies. Because of this balance between brightness and warmth of maple, it merges completely with membranes from very thin to thick, giving a drum overall pleasant voice character.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0056/BF0056.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0056/BF0056_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0056/BF0056_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0056/BF0056-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0056/BF0056-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0055',
        'name': 'BF0055',
        'wood': 'maple',
        'skin': 'calf',
        'diameterInch': 20,
        'diameterCm': 50,
        'descriptionShort': '20" maple frame with calf skin',
        'price': 485,
        'status': 'On stock',
        'descriptionLong': 'This frame drum is made from maple, which is perceived as one of the most popular wood species for drum shells. It creates balanced and warm sound with a decent level of highs and mid level tones and full low frequencies. Because of this balance between brightness and warmth of maple, it merges completely with membranes from very thin to thick, giving a drum overall pleasant voice character.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0055/BF0055.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0055/BF0055_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0055/BF0055_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0055/BF0055-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0055/BF0055-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0054',
        'name': 'BF0054',
        'wood': 'ash',
        'skin': 'deer',
        'diameterInch': 20,
        'diameterCm': 50,
        'descriptionShort': '20" ash frame with deer skin',
        'price': 485,
        'status': 'On stock',
        'descriptionLong': 'This frame drum of ash, known for its distinct and dry sound for its density, with warm highs and mids and average lows. Virtually any membrane will sound unique with this wood, thus thicker skins, especially deer and calf will tune this drum to exceptional and strong voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0054/BF0054.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0054/BF0054_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0054/BF0054_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0054/BF0054-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0054/BF0054-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0053',
        'name': 'BF0053',
        'wood': 'maple',
        'skin': 'deer',
        'diameterInch': 20,
        'diameterCm': 50,
        'descriptionShort': '20" maple frame with deer skin',
        'price': 485,
        'status': 'On stock',
        'descriptionLong': 'This frame drum is made from maple, which is perceived as one of the most popular wood species for drum shells. It creates balanced and warm sound with a decent level of highs and mid level tones and full low frequencies. Because of this balance between brightness and warmth of maple, it merges completely with membranes from very thin to thick, giving a drum overall pleasant voice character.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0053/BF0053.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0053/BF0053_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0053/BF0053_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0053/BF0053-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0053/BF0053-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0051',
        'name': 'BF0051',
        'wood': 'ash',
        'skin': 'deer',
        'diameterInch': 18,
        'diameterCm': 45,
        'descriptionShort': '18" ash frame with deer skin',
        'price': 455,
        'status': 'Sold',
        'descriptionLong': 'This frame drum of ash, known for its distinct and dry sound for its density, with warm highs and mids and average lows. Virtually any membrane will sound unique with this wood, thus thicker skins, especially deer and calf will tune this drum to exceptional and strong voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0051/BF0051.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0051/BF0051_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0051/BF0051_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0051/BF0051-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0051/BF0051-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0050',
        'name': 'BF0050',
        'wood': 'walnut',
        'skin': 'calf',
        'diameterInch': 18,
        'diameterCm': 45,
        'descriptionShort': '18" walnut frame with calf skin',
        'price': 455,
        'status': 'On stock',
        'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0050/BF0050.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0050/BF0050_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0050/BF0050_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0050/BF0050-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0050/BF0050-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0046',
        'name': 'BF0046',
        'wood': 'ash',
        'skin': 'deer',
        'diameterInch': 18,
        'diameterCm': 45,
        'descriptionShort': '18" ash frame with deer skin',
        'price': 455,
        'status': 'On stock',
        'descriptionLong': 'This frame drum of ash, known for its distinct and dry sound for its density, with warm highs and mids and average lows. Virtually any membrane will sound unique with this wood, thus thicker skins, especially deer and calf will tune this drum to exceptional and strong voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0046/BF0046.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0046/BF0046_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0046/BF0046_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0046/BF0046-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0046/BF0046-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0045',
        'name': 'BF0045',
        'wood': 'walnut',
        'skin': 'calf',
        'diameterInch': 16,
        'diameterCm': 40,
        'descriptionShort': '16" walnut frame with calf skin',
        'price': 435,
        'status': 'On stock',
        'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0045/BF0045.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0045/BF0045_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0045/BF0045_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0045/BF0045-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0045/BF0045-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0043',
        'name': 'BF0043',
        'wood': 'ash',
        'skin': 'calf',
        'diameterInch': 22,
        'diameterCm': 55,
        'descriptionShort': '22" ash frame with calf skin',
        'price': 505,
        'status': 'Sold',
        'descriptionLong': 'This frame drum of ash, known for its distinct and dry sound for its density, with warm highs and mids and average lows. Virtually any membrane will sound unique with this wood, thus thicker skins, especially deer and calf will tune this drum to exceptional and strong voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0043/BF0043.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0043/BF0043_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0043/BF0043_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0043/BF0043-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0043/BF0043-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0042',
        'name': 'BF0042',
        'wood': 'maple',
        'skin': 'deer',
        'diameterInch': 14,
        'diameterCm': 35,
        'descriptionShort': '14" maple frame with deer skin',
        'price': 415,
        'status': 'Sold',
        'descriptionLong': 'This frame drum is made from maple, which is perceived as one of the most popular wood species for drum shells. It creates balanced and warm sound with a decent level of highs and mid level tones and full low frequencies. Because of this balance between brightness and warmth of maple, it merges completely with membranes from very thin to thick, giving a drum overall pleasant voice character.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0042/BF0042.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0042/BF0042_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0042/BF0042_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0042/BF0042-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0042/BF0042-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0041',
        'name': 'BF0041',
        'wood': 'walnut',
        'skin': 'deer',
        'diameterInch': 18,
        'diameterCm': 45,
        'descriptionShort': '18" walnut frame with deer skin',
        'price': 455,
        'status': 'On stock',
        'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0041/BF0041.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0041/BF0041_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0041/BF0041_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0041/BF0041-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0041/BF0041-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0037',
        'name': 'BF0037',
        'wood': 'oak',
        'skin': 'deer',
        'diameterInch': 16,
        'diameterCm': 40,
        'descriptionShort': '16" oak frame with deer skin',
        'price': 435,
        'status': 'On stock',
        'descriptionLong': 'This oak made frame drum belongs to some of the  most powerful and solid, as well as playful woods we have worked with. With intelligence of its own, oak shell offers exceptionally warm sound with soft highs, good midrange, and fairly warm lows. It delivers fast projection with full-bodied presence sound, specifically with semi thick skins from our membranes range.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0037/BF0037.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0037/BF0037_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0037/BF0037_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0037/BF0037-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0037/BF0037-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
        'sn': 'BF0035',
        'name': 'BF0035',
        'wood': 'maple',
        'skin': 'deer',
        'diameterInch': 14,
        'diameterCm': 35,
        'descriptionShort': '14" mapple frame with deer skin',
        'price': 415,
        'status': 'On stock',
        'descriptionLong': 'This frame drum is made from maple, which is perceived as one of the most popular wood species for drum shells. It creates balanced and warm sound with a decent level of highs and mid level tones and full low frequencies. Because of this balance between brightness and warmth of maple, it merges completely with membranes from very thin to thick, giving a drum overall pleasant voice character.',
        'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0035/BF0035.jpg',
        'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0035/BF0035_small.jpg',
        'images': [
            'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0035/BF0035_back.jpg',
        ],
        'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0035/BF0035-L.mp3',
        'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0035/BF0035-H.mp3',
        'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
    },
    {
    'sn': 'BF0033',
    'name': 'BF0033',
    'wood': 'walnut',
    'skin': 'deer',
    'diameterInch': 18,
    'diameterCm': 45,
    'descriptionShort': '18" walnut frame with deer skin',
    'price': 455,
    'status': 'Sold',
    'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
    'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0033/BF0033.jpg',
    'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0033/BF0033_small.jpg',
    'images': [
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0033/BF0033_back.jpg',
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0033/02.jpg',
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0033/03.jpg',
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0033/04.jpg',
    ],
    'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0033/BF0033-L.mp3',
    'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0033/BF0033-H.mp3',
    'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
},
{
    'sn': 'BF0032',
    'name': 'BF0032',
    'wood': 'cherry',
    'skin': 'deer',
    'diameterInch': 22,
    'diameterCm': 55,
    'descriptionShort': '22" cherry frame with deer skin',
    'price': 505,
    'status': 'On stock',
    'descriptionLong': 'This frame drum made of cherry wood has an extremely warm tone with pronounced highs, punchy midrange, and sensitive and bright sound color. It matches well with all kinds of skin types and thicknesses as the woods medium sustain brings out a balanced sound of any membrane.',
    'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0032/BF0032.jpg',
    'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0032/BF0032_small.jpg',
    'images': [
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0032/BF0032_back.jpg',
    ],
    'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0032/BF0032-L.mp3',
    'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0032/BF0032-H.mp3',
    'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
},
{
    'sn': 'BF0031',
    'name': 'BF0031',
    'wood': 'walnut',
    'skin': 'calf',
    'diameterInch': 24,
    'diameterCm': 60,
    'descriptionShort': '24" walnut frame with calf skin',
    'price': 635,
    'status': 'Sold',
    'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
    'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0031/BF0031.jpg',
    'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0031/BF0031_small.jpg',
    'images': [
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0031/BF0031_back.jpg',
    ],
    'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0031/BF0031-L.mp3',
    'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0031/BF0031-H.mp3',
    'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
},
{
    'sn': 'BF0027',
    'name': 'BF0027',
    'wood': 'walnut',
    'skin': 'deer',
    'diameterInch': 18,
    'diameterCm': 45,
    'descriptionShort': '18" walnut frame with deer skin',
    'price': 455,
    'status': 'On stock',
    'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
    'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0027/BF0027.jpg',
    'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0027/BF0027_small.jpg',
    'images': [
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0027/BF0027_back.jpg',
    ],
    'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0027/BF0027-L.mp3',
    'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0027/BF0027-H.mp3',
    'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
},
{
    'sn': 'BF0022',
    'name': 'BF0022',
    'wood': 'cherry',
    'skin': 'deer',
    'diameterInch': 22,
    'diameterCm': 55,
    'descriptionShort': '22" cheery frame with deer skin',
    'price': 505,
    'status': 'On stock',
    'descriptionLong': 'This frame drum made of cherry wood has an extremely warm tone with pronounced highs, punchy midrange, and sensitive and bright sound color. It matches well with all kinds of skin types and thicknesses as the woods medium sustain brings out balanced sound of any membrane.',
    'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0022/BF0022.jpg',
    'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0022/BF0022_small.jpg',
    'images': [
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0022/BF0022_back.jpg',
    ],
    'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0022/BF0022-L.mp3',
    'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0022/BF0022-H.mp3',
    'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
},
{
    'sn': 'BF0020',
    'name': 'BF0020',
    'wood': 'walnut',
    'skin': 'deer',
    'diameterInch': 22,
    'diameterCm': 55,
    'descriptionShort': '22" walnut frame with deer skin',
    'price': 505,
    'status': 'Sold',
    'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
    'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0020/BF0020.jpg',
    'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0020/BF0020_small.jpg',
    'images': [
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0020/BF0020_back.jpg',
    ],
    'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0020/BF0020-L.mp3',
    'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0020/BF0020-H.mp3',
    'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
},
{
    'sn': 'BF0019',
    'name': 'BF0019',
    'wood': 'walnut',
    'skin': 'deer',
    'diameterInch': 24,
    'diameterCm': 60,
    'descriptionShort': '24" walnut frame with deer skin',
    'price': 525,
    'status': 'On stock',
    'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
    'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0019/BF0019.jpg',
    'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0019/BF0019_small.jpg',
    'images': [
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0019/BF0019_back.jpg',
    ],
    'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0019/BF0019-L.mp3',
    'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0019/BF0019-H.mp3',
    'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
},
{
    'sn': 'BF0013',
    'name': 'BF0013',
    'wood': 'walnut',
    'skin': 'deer',
    'diameterInch': 20,
    'diameterCm': 50,
    'descriptionShort': '20" walnut frame with deer skin',
    'price': 485,
    'status': 'On stock',
    'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
    'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0013/BF0013.jpg',
    'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0013/BF0013_small.jpg',
    'images': [
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0013/BF0013_back.jpg',
    ],
    'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0013/BF0013-L.mp3',
    'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0013/BF0013-H.mp3',
    'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
},
{
    'sn': 'BF0011',
    'name': 'BF0011',
    'wood': 'walnut',
    'skin': 'goat',
    'diameterInch': 14,
    'diameterCm': 35,
    'descriptionShort': '14" walnut frame with goat skin',
    'price': 395,
    'status': 'On stock',
    'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
    'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0011/BF0011.jpg',
    'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0011/BF0011_small.jpg',
    'images': [
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0011/BF0011_back.jpg',
    ],
    'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0011/BF0011-L.mp3',
    'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0011/BF0011-H.mp3',
    'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
},
{
    'sn': 'BF0008',
    'name': 'BF0008',
    'wood': 'walnut',
    'skin': 'deer',
    'diameterInch': 14,
    'diameterCm': 35,
    'descriptionShort': '14" walnut frame with deer skin',
    'price': 455,
    'status': 'Sold',
    'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
    'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0008/BF0008.jpg',
    'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0008/BF0008_small.jpg',
    'images': [
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0008/BF0008_back.jpg',
    ],
    'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0008/BF0008-L.mp3',
    'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0008/BF0008-H.mp3',
    'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
},
{
    'sn': 'BF0006',
    'name': 'BF0006',
    'wood': 'walnut',
    'skin': 'sheep',
    'diameterInch': 20,
    'diameterCm': 50,
    'descriptionShort': '20" walnut frame with sheep skin',
    'price': 465,
    'status': 'On stock',
    'descriptionLong': 'This frame drum made of walnut wood produces warm and well balanced sound in general, with lows, mids and high tones equal. Especially well matched with a thin deer or calf skin, it creates a beautiful and mellow, but focused and unique voice.',
    'mainImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0006/BF0006.jpg',
    'smallImageUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0006/BF0006_small.jpg',
    'images': [
        'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0006/BF0006_back.jpg',
    ],
    'audioLowPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0006/BF0006-L.mp3',
    'audioHighPitchUrl': 'https://brainframedrums.s3.eu-west-3.amazonaws.com/products/BF0006/BF0006-H.mp3',
    'videoUrl': 'https://www.youtube.com/embed/-UYgORr5Qhg',
},

];

export default products;